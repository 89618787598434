.contact-form-wrapper {
  background-color: #fff;
  padding: 20px;
  overflow: auto;
  height: 100%;

  .contact-form-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    min-height: 100%;
    justify-content: space-between;
  }

  .contact-us-headline {
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
    background-color: #e5e5e5;
    padding: 20px;
  }

  .contact-form-field-container {
    margin: 0;
    .telegram {
      font-size: 1rem;
      padding: 5px;
      width: 50%;
      border-radius: 6px;
      display: inline-block;
    }
    .contact-form-telegram-container {
      padding: 6px 0;
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 10px;
    }
    label {
      font-size: 1rem;
      font-weight: bold;
      display: inline-block;
      width: 40%;
      text-align: right;
      padding-right: 10px;
    }

    input {
      border: 1px solid #707070;
      font-size: 1rem;
      padding: 5px;
      width: 50%;
      border-radius: 6px;
    }

    textarea {
      border: 1px solid #707070;
      font-size: 1rem;
      padding: 5px;
      width: 50%;
      border-radius: 6px;
    }

    label {
      vertical-align: top;
      line-height: 36px;
    }

    &.contact-form-field-container--send-button {
      button {
        width: 100%;
        padding: 20px;
        font-size: 1.4rem;
        background-color: $background-color__contact-form-sumit-btn;
        color: $color__contact-form-sumit-btn;
        border-radius: 10px;
        font-weight: bold;

        &:hover {
          background-color: $background-color__contact-form-sumit-btn__active;
          color: $color__contact-form-sumit-btn__active;
        }
      }
    }
  }

  .msg-sending-progress-container {
    background-color: orange;
    color: #fff;
    padding: 2px 10px;
  }

  .msg-sending-status {
    color: #fff;
    padding: 2px 10px;
    font-size: 0.9rem;

    &.status-success {
      background-color: green;
    }

    &.status-fail {
      background-color: red;
    }
  }

  .contact-form-field-errors-container {
    padding: 6px 0;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 10px;
    p {
      background-color: red;
      color: #fff;
      padding: 2px 10px;
      margin-bottom: 1px;
    }
  }
}

.contacts-form-wrapper .overlay-container {
  margin: 0 auto;
  width: 600px;
  min-height: 50%;
  max-height: 95%;
  height: 750px;

  .overlay-child {
    height: 100%;
  }
}

@media screen and (max-width: $screen-width-tablet-max-width) {
  .contacts-form-wrapper .overlay-container {
    padding-top: 30px;
  }
}

@media screen and (max-width: $screen-width-mobile-max-width) {
  .contacts-form-wrapper .overlay-container {
    padding: 30px 10px 10px;
    width: 90%;

    .login-block-content {
      padding: 30px;
    }
  }

  .contact-form-wrapper {
    padding: 10px;
  }
}
