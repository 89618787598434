@media screen and (min-width:$screen-width-tablet-min-width) and (max-width: $screen-width-full-max-width) {
    .sports-moneyline .sports-moneyline_game{
        height: auto;
    }

    .sports-moneyline_oddsColumn{
        flex-direction: column;
        width: auto;
    }
}

@media screen and (min-width:$screen-width-tablet-min-width) and (max-width: $screen-width-full-max-width){
    .sports-moneyline .sports-moneyline_game .sports-moneyline_time,
    .sports-moneyline.outright .sports-moneyline_time{
        flex: 50px;
        width: 50px;
        max-width: 50px;
    }
    .sports-moneyline .sports-moneyline_game .sports-moneyline_link .sports-moneyline_betCount{
        padding: 0;
    }
    .content-main .content-right{
        min-width: 340px;
    }
}

@media screen and (max-width: $screen-width-mobile-max-width) {
    .sports-moneyline .sports-moneyline_game .sports-moneyline_time,
    .sports-moneyline.outright .sports-moneyline_time{
        flex: 55px;
        width: 55px;
        min-width: 55px;
        flex-grow: 0;
    }

    .sports-moneyline_oddsColumn{
        width: 55px;
    }
}
