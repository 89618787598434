.change-password-popup-wrapper {
    width: 550px;

    .change-password-popup-title {
        background-color: #e5e5e5;
        font-size: 1.3rem;
        font-weight: 700;
        padding: 20px;
        text-align: center;
        margin-bottom: 20px;
    }

    .change-password-field-container {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #e5e5e5;

        .change-password-field {
            label {
                display: inline-block;
                font-size: 1rem;
                font-weight: 700;
                padding-right: 10px;
                text-align: right;
                width: 40%;
            }

            input {
                border: 1px solid #707070;
                border-radius: 6px;
                font-size: 1rem;
                padding: 5px;
                width: 50%;
            }
        }

        .change-password-validation-errors p {
            background-color: red;
            color: #fff;
            margin-bottom: 1px;
            padding: 2px 10px;

            &:first-of-type {
                margin-top: 7px;
            }
        }
    }

    .change-password-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .change-password-button {
            border-radius: 10px;
            font-size: 1.4rem;
            font-weight: 700;
            padding: 20px;
            width: 100%;
            text-align: center;
            cursor: pointer;
        }

        .change-password-submit-button {
            background-color: $theme-bg-color-1;
            color: $theme-text-color-1;

            &[disabled] {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        .change-password-retry-button {
            background-color: $theme-bg-color-11;
            color: $theme-text-color-11;
        }

        .change-password-close-button {
            background-color: $theme-bg-color-1;
            color: $theme-text-color-1;
        }

        .change-password-logout-button {
            background-color: $theme-bg-color-11;
            color: $theme-text-color-11;
        }
    }

    .change-password-request-response-message {
        padding: 10px;
        margin: 10px 0;

        &.success {
            background-color: $theme-bg-color-11;
            color: $theme-text-color-11;
        }

        &.error {
            background-color: $theme-bg-color-12;
            color: $theme-text-color-12;
        }
    }
}

@media screen and (max-width: 600px) {
    .change-password-modal-wrapper {
        .modal-container {
           width: 90%;
        }
    }

    .change-password-popup-wrapper {
        width: 100%;
        max-height: 75vh;
        overflow-y: auto;
     
        .change-password-field-container { 
            .change-password-field {
                label {
                    width: 100%;
                    text-align: left;
                }
    
                input {
                    width: 100%;
                }
            }
        }
    }
}