//seamless styling:
.mainHeader_seamless {
  display: flex;
  height: 40px;
  background-color: $theme-bg-color-6;
}
.mainHeader_seamless .headerMenu {
  display: flex;
  align-items: center;

  .header_seamless {
    position: absolute !important;
    left: 50%;
    transform: translateX(-50%);
  }
}

.mainHeader_seamless .headerMenu_link {
  display: inline-block;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 700;
  color: $theme-text-color-1;
  line-height: 28px;
}
.header-seamless-account-button {
  position: absolute;
  right: 10px !important;
}
.mobile_only {
  .seamless-my-markets {
    position: absolute;
    right: 134px !important;
  }
  .seamless-open-bets {
    position: absolute;
    right: 72px !important;
  }
}

.header-account-seamless {
  display: flex;
  flex-direction: column;
  font-size: 9px;
  font-weight: normal;
  color: #fff;
  line-height: normal;
  border: none;
  text-align: center;
  height: 45px;
  width: 57px;
  .account_icon-username {
    padding: 3px 0px !important;
    height: 17px;
    font-size: 10px;
  }
  .iconSeamless {
    height: 28px;
    width: 57px;
    display: flex;
    justify-content: center;
    .user-icon-mobile-seamless {
      display: block;
      height: 24px;
      width: 32px;
      margin: 2px 0px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
.account-dropdown-seamless {
  top: 108% !important;
  right: -10px !important;
}

@media screen and (min-width: $screen-width-tablet-min-width) {
  //seamless:
  .mainHeader_seamless .headerMenu_link:hover,
  .mainHeader_seamless .headerMenu_link.active {
    color: $color__mainHeader_link__active;
    background-color: $background-color__mainHeader;
  }
}

@media screen and (max-width: $screen-width-tablet-max-width) {
  //seamless:
  .mainHeader_seamless {
    background-color: $theme-bg-color-1;
    height: auto;
    .headerMenu {
      background-color: #303030;
      width: 100%;
    }

    .headerMenu_link {
      font-size: 12px;
      padding: 5px;
      margin: 6px;
      border-radius: 5px;
      height: 40px;
      color: #fff;
      &.active {
        background-color: $theme-bg-color-1;
        color: $theme-text-color-1;
      }
    }

    .header-betslip-button,
    .header-open-bets-button {
      width: 53px;
      height: 45px;
      position: absolute;
      right: 67px;
      top: 3px;
      border-radius: 6px;

      &.active {
        background-color: $theme-bg-color-1;
      }

      .header-betslip-button-icon {
        display: block;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .header-my-markets-btn,
    .header-open-bets-button {
      border-radius: 0;
      background-color: #7d7d7d;
      margin-left: 5px;
      width: 57px;

      &.active {
        background-color: $theme-bg-color-1;
        color: $theme-text-color-1;
      }
    }
  }
}

@media screen and (max-width: $screen-width-mobile-max-width) {
  //seamless:
  .mainHeader_seamless .headerMenu {
    .header_seamless {
      right: 196px;
      display: flex;
      align-items: center;
      transform: none;
      left: auto;
      margin-bottom: 2px;

      .search_wrapper_seamless {
        width: 45px !important;
        height: 45px !important;
        margin: 0px !important;
        border-radius: 0px !important;
        .search_button {
          width: 27px !important;
          height: 27px !important;
        }
      }
      .expanded {
        width: 100% !important;
        height: auto !important;
        margin-bottom: 10px !important;
        bottom: 10px;
        .search_button {
          width: 24px !important;
          height: 24px !important;
        }
      }
    }
  }
}

//seamless:
@media screen and (max-width: 430px) {
  .header_seamless {
    position: relative;
    display: none !important;
  }
}
