.progress-bar-wrapper {
    position: sticky;
    width: 100%;
    top: calc(100% - 20px);
    background-color: darkgreen;
    height: 20px;

    .progress-bar-indicator {
        background-color: green;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
    }

    .progress-bar-text {
        color: #FFF;
        text-align: center;
        position: relative;
        z-index: 1;
    }
}