.sports-page-mobile-wrapper{
    .sports-page-mobile-item{
        background-color: #FFF;
        padding: 10px;
        border-bottom: 1px solid #444;
        font-size: 14px;

        &.sports-page-mobile-event-link {
            padding: 5px 20px;
            background-color: #ededed;
        }

        &.sports-page-mobile-title{
            background-color: #CCC;
            border-top: 2px solid #000;
        }

        &.sports-page-mobile-link-container a{
            display: block;
        }
    }
}