.favorites-button {
    width: 22px;
    height: 22px;
    background: transparent no-repeat
      center center;
    border: 0;
    background-image: $icon-events-favorites-path;

    &:not(.touched):hover {
        background-image: $icon-events-favorites-hover-path;
    }

    &.active {
        background-image: $icon-events-favorites-active-path;
        &:not(.touched):hover {
            background-image: $icon-events-favorites-active-hover-path;
        }
    }

    &.in-progress {
        background-image: $icon-events-favorites-progress-path;
        cursor: default;
        pointer-events: none;
        &:not(.touched):hover {
            background-image: $icon-events-favorites-progress-path;
        }
    }

    &.market-props-fav-btn {
        background-image: $icon-market-favorites-path;

        &:not(.touched):hover {
            background-image: $icon-events-favorites-hover-path;
        }

        &.active {
            background-image: $icon-events-favorites-active-path;
            &:not(.touched):hover {
                background-image: $icon-market-favorites-active-hover-path;
            }
        }

        &.in-progress {
            background-image: $icon-market-favorites-progress-path;
            cursor: default;
            pointer-events: none;
            &:not(.touched):hover {
                background-image: $icon-market-favorites-progress-path;
            }
        }
    }
}

