   
  
  .sports-moneyline .sports-moneyline_title .sports-moneyline_oddsColumn {
    justify-content: center;
  }
  
  .sports-moneyline .sports-moneyline_title .sports-moneyline_eventType {
    box-sizing: border-box;
    padding-left: 10px;
  }
  
  .sports-moneyline .sports-moneyline_game:hover {
    background-color: #f6f6f6;
  }
  
  .sports-moneyline
    .sports-moneyline_game
    .sports-moneyline_link
    .sports-moneyline_eventName {
    font-weight: 700;
    display: flex;
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    padding-left: 10px;
    font-size: 12px;
  }
  .sports-moneyline
    .sports-moneyline_game
    .sports-moneyline_link
    .sports-moneyline_betCount {
    display: flex;
    align-self: stretch;
    align-items: center;
    padding-right: 23px;
  }
  .sports-moneyline
    .sports-moneyline_game
    .sports-moneyline_link
    .sports-moneyline_betCount
    span {
    display: inline-block;
    width: 48px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    border-radius: 9px;
    background-color: #158e50;
  }
  .sports-moneyline .sports-moneyline_game .sports-moneyline_time,
  .sports-moneyline.outright .sports-moneyline_time {
    width: 56px;
    flex: 56px;
    min-width: 56px;
    flex-grow: 0;
    display: flex;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    background-color: $backround-color__sports_moneyline_Time;
    color: $color__sports_moneyline_Time;
    font-size: 11px;
    min-height: 63px;
  }
  .sports-moneyline .sports-moneyline_game .sports-moneyline_time.live,
  .sports-moneyline.outright .sports-moneyline_time.live {
    background-color: $backround-color__sports_moneyline_Time__Live;
    color: $color__sports_moneyline_Time__Live;
    text-decoration: none;
  }
  .sports-moneyline .sports-moneyline_game .sports-moneyline_link:hover{
    text-decoration: none;
  }
  .sports-moneyline
    .sports-moneyline_game
    .sports-moneyline_time
    .sports-moneyline_time_row {
    width: 100%;
    text-align: center;
  }
  .sports-moneyline_game,
  .event-content.market-wrapper{
    position: relative;
  }
  .sports-moneyline .sports-moneyline_game-status,
  .event-content.market-wrapper .sports-moneyline_game-status{ 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF99;
    text-align: center;
    line-height: 45px;
    color: #FF0000;
    z-index: 3;
    font-weight: bold;
    vertical-align: middle;
    border: 2px solid #FF0000;
  }
  .sports-moneyline .sports-moneyline_game .sports-moneyline_matched {
    color: #919191;
    display: flex;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: bold;
  }
  .sports-moneyline .sports-selection_options-close button {
    background: none;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    position: relative;
    right: 8px;
  }
  .sports-moneyline .sports-selection_options-risk-options {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 42px 0 0;
  }
  .sports-moneyline .sports-selection_options-risk-options label {
    color: #6f6b73;
    font-size: 13px;
    font-weight: 700;
    position: relative;
    top: 6px;
  }
  .sports-moneyline .sports-selection_options {
    display: flex;
    margin-bottom: 1px;
    min-height: 61px;
    align-items: center;
    flex-wrap: wrap;
  }
  .sports-moneyline .sports-selection_options.bet-option {
    background-color: #d2ebff;
  }
  .sports-moneyline .sports-selection_options.bet-accepted {
    color: #fff;
    background-color: #158e50;
  }
  .sports-moneyline .sports-selection_options.bet-change {
    background-color: #f3dce2;
  }
  .sports-moneyline .sports-selection_options .sports-selection_options-info {
    flex: auto;
    padding-left: 23px;
  }
  .sports-moneyline .sports-selection_options .sports-selection_options-controls {
    box-sizing: border-box;
    padding: 0 42px 0 0;
    font-size: 0;
    height: 61px;
    display: flex;
    align-items: center;
  }
  .sports-moneyline
    .sports-selection_options
    .sports-selection_options-controls
    > * {
    border-radius: 4px;
    height: 34px;
    box-sizing: content-box;
    margin-left: 10px;
  }
  .sports-moneyline
    .sports-selection_options
    .sports-selection_options-controls
    .sports-selection_options-cancel,
  .sports-moneyline
    .sports-selection_options
    .sports-selection_options-controls
    .sports-selection_options-bet,
  .sports-moneyline
    .sports-selection_options
    .sports-selection_options-controls
    .sports-selection_options-place-bet {
    padding: 0 35px;
    font-size: 13px;
    font-weight: 700;
  }
  .sports-moneyline
    .sports-selection_options
    .sports-selection_options-controls
    .sports-selection_options-cancel {
    background-color: #bfbfbf;
  }
  .sports-moneyline
    .sports-selection_options
    .sports-selection_options-controls
    .sports-selection_options-cancel:hover {
    background-color: #b2b2b2;
  }
  .sports-moneyline
    .sports-selection_options
    .sports-selection_options-controls
    .sports-selection_options-stake {
    width: 112px;
    text-align: center;
    font-size: 15px;
    box-sizing: border-box;
    padding: 0;
  }
  .sports-moneyline
    .sports-selection_options
    .sports-selection_options-controls
    .sports-selection_options-bet {
    color: #808080;
    background-color: #fff;
  }
  .sports-moneyline
    .sports-selection_options
    .sports-selection_options-controls
    .sports-selection_options-bet:hover {
    background-color: #f2f2f2;
  }
  .sports-moneyline
    .sports-selection_options
    .sports-selection_options-controls
    .sports-selection_options-place-bet {
    color: #865f00;
    background-color: #ffdc86;
  }
  .sports-moneyline
    .sports-selection_options
    .sports-selection_options-controls
    .sports-selection_options-place-bet:hover {
    background-color: #ffd56c;
  }
  .sports-moneyline_icons {
    width: 36px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 3px;
    box-sizing: border-box;
  }
  .sports-moneyline_icons .sports-moneyline_iconse_inner > div {
    width: 24px;
    height: 16px;
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 6px 0;
  }
  .sports-moneyline_icons_live {
    background-image: $icon-events-live-icon-path;
  }
  .sports-moneyline_icons_video {
    background-image: $icon-events-video-icon-path;
  }

  .liveStreaming {
    width: 100%;
    box-sizing: border-box;
  }
  .liveStreaming .liveStreaming-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .liveStreaming .liveStreaming-header {
    height: 36px;
    width: 100%;
    padding: 0 28px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: #303030;
    font-size: 15px;
    color: #fff;
    font-weight: 700;
  }
  .liveStreaming .liveStreaming-header span {
    padding-right: 20px;
  }
  .liveStreaming .liveStreaming-header a {
    color: #3070de;
  }
  .liveStreaming .liveStreaming-tabs {
    width: 100%;
    display: flex;
    height: 33px;
    border-bottom: 1px solid #dcdcdc;
    font-size: 15px;
    font-weight: 700;
    align-items: center;
  }
  .liveStreaming .liveStreaming-tabs .liveStreaming-singleTab {
    width: 50%;
    display: flex;
    text-align: center;
    align-items: center;
    color: #fff;
    background-color: #6e6e6e;
    height: 33px;
    justify-content: center;
  }
  .liveStreaming .liveStreaming-tabs .liveStreaming-singleTab.active,
  .liveStreaming .liveStreaming-tabs .liveStreaming-singleTab:hover {
    color: #000;
    background-color: #dcdcdc;
  }
  .liveStreaming .liveStreaming-videoFeed {
    min-height: 232px;
    width: 100%;
    background-color: #000;
    display: flex;
  }
  .liveStreaming .liveStreaming-videoFeed .liveStreaming-overlay {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
    align-self: center;
  }
  
  
  .p-4-origin-country{
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
  .p-4-coutry-flag-twemoji{
    height: 20px;
  }
  
  
   /*
    *
    *
    * Report pages
    *
    *
    */
    .report-page-title{
      font-weight: bold;
      font-size: 1.1rem;
      padding: 20px 20px 0;
    }
  
    .report-page-filters-delimiter{
      display: block;
      padding: 7px 0;
    }
  
    .report-radio-label{
      width: 160px;
    }
  
    .report-date-range-input .bp3-control-group{
      gap: 10px;
    }
  
    .reports-table-wrapper{
      padding: 0 12px;
    }
  
    .reports-table{
      width: 100%;
    }
  
    .reports-table-header-row{
      background-color: #d9e8ea;
    }
  
    .reports-table-header-row th{
      font-weight: bold;
      text-align: left;
      padding: 4px 8px;
    }
  
    .reports-table-row{
      border-bottom: 1px solid #d9e8ea;
    }
  
    .reports-table-row td{
      padding: 4px 8px;
    }
  
   /*
    *
    *
    * PROFIT AND LOSS PAGE
    *
    *
    */
  
    .profit-and-loss-filters-wrapper, 
    .report-filters-container{
      background-color: #b1c8d9;
      width: 100%;
    }
  
   .profit-loss-totals-wrapper{
     display: flex;
     padding: 10px 20px 25px;
     gap: 20px;
   }
  
   .profit-loss-total{
      color: #3070de;
      cursor: pointer;
      text-decoration: underline;
      font-weight: bold;
   }
  
   .profit-loss-total.selected{
     font-weight: bold;
     color: #000;
     text-decoration: none;
   }
  
   .profit-loss-total:hover{
      text-decoration: underline;
   }
  
   .market-profit-and-loss-table-wrapper{
     padding: 0 20px;
   }
  
   .market-profit-and-loss-details-wrapper{
     text-align: right;
     padding: 20px;
   }
  
   .market-profit-and-loss-detail-value{
     display: inline-block;
     width: 200px;
   }
  
   .market-profit-and-loss-market-info{
     padding: 10px 25px;
     border-top: 1px solid #ccc;
   }
  
   .market-profit-loss-info-sport-name{
     font-weight: bold;
   }
  
   .market-profit-loss-info-event-name{
    color: #999;
   }
  
   .profit-loss-market-link{
     color: #3070de;
     text-decoration: underline;
     cursor: pointer;
   }
  
   .profit-loss-pages-container{
     padding: 20px;
     display: flex;
     gap: 10px;
   }
  
   .profit-loss-page-item{
    color: #3070de;
    text-decoration: underline;
    cursor: pointer;
   }
  
   .profit-loss-page-item.active{
     color: #000;
     font-weight: bold;
     text-decoration: none;
     cursor: auto;
   }
  
   .profit-loss-results-info-container{
     padding: 5px 20px;
     display: flex;
     justify-content: space-between;
   }
  
   .profit-loss-results-info-listed-items-numbers{
     font-weight: bold;
   }
  
   