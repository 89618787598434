// LAYOUT VARIABLES
$screen-width-full-max-width: 1245px;
$screen-width-tablet-max-width: 1024px;
$screen-width-mobile-max-width: 600px;

$screen-width-full-min-width: 1246px;
$screen-width-tablet-min-width: 1025px;
$screen-width-mobile-min-width: 601px;

// THEME FONTS
$font-family-1: Arial, Helvetica, sans-serif;
$font-family-2: 'Arial Narrow', Arial, Helvetica, sans-serif;

// ICONS
$icons-set: default;
$icons-color-1: '000';
$icons-color-2: 'AFAFAF';
$icons-color-3: '919191';
$icons-color-4: 'CCC';
$icons-color-5: '20a052';
$icons-color-6: '000';
$icons-color-7: 'FFF';
$icons-color-8: 'ffb80c';

// THEME COLORS
// background colors
$theme-bg-color-1: #ffb80c;
$theme-bg-color-1-active: darken($theme-bg-color-1, 10%);
$theme-bg-color-1-inactive: lighten($theme-bg-color-1, 30%);

$theme-bg-color-2: #e5e5e5;
$theme-bg-color-3: #FFF;
$theme-bg-color-4: #b2b2b2;
$theme-bg-color-5: #171717;
$theme-bg-color-6: #303030;
$theme-bg-color-7: #20a052;
$theme-bg-color-8: #a6d8ff;
$theme-bg-color-8-active: #6cbdff;
$theme-bg-color-8-inactive: #d2ebff;
$theme-bg-color-9: #fac9d1;
$theme-bg-color-9-active: #f694aa;
$theme-bg-color-9-inactive: #FFE9ED;
$theme-bg-color-10: #d2ebff;
$theme-bg-color-11: #20a052; // success color
$theme-bg-color-12: #F00; // fail color
$theme-bg-color-13: #f6f6f6;
$theme-bg-color-14: #000;
$theme-bg-color-15: #dfe5e5;
$theme-bg-color-16: #FFF;
$theme-bg-color-17: #6e6e6e;
$theme-bg-color-18: #707070;
$theme-bg-color-19: #0e72b6;
$theme-bg-color-20: #0243fb;

// text colors
$theme-text-color-1: #000;
$theme-text-color-1-active: #000;
$theme-text-color-1-inactive: $theme-bg-color-1;

$theme-text-color-2: #000;
$theme-text-color-3: #000;
$theme-text-color-4: #000;
$theme-text-color-5: #FFF;
$theme-text-color-6: #FFF;
$theme-text-color-7: #000;
$theme-text-color-8: #000;
$theme-text-color-8-active: #000;
$theme-text-color-8-inactive: #000;
$theme-text-color-9: #000;
$theme-text-color-9-active: #000;
$theme-text-color-9-inactive: #000;
$theme-text-color-10: #000;
$theme-text-color-11: #FFF; // success color
$theme-text-color-12: #FFF; // fail color
$theme-text-color-13: #000;
$theme-text-color-14: #FFF;
$theme-text-color-15: #000;
$theme-text-color-16: #559cd5;
$theme-text-color-17: #FFF;
$theme-text-color-18: #FFF;
$theme-text-color-19: #FFF;
