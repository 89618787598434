.sports-moneyline .sports-moneyline_title{
  font-size: 12px;
  background-color: $background-color__sports-moneyline_Title;
  color: $color__sports-moneyline_Title;

  &.outhright_event_title {
    background-color: $background-color__sports-moneyline--ouright_Title;
    color: $color__sports-moneyline--outright_Title;
    height: auto;

    .sports-moneyline_link {
      align-items: center;
    }

    .sports-moneyline_oddsColumn_wrapper {
      padding-right: 5px;
    }
  }
}

.sports-moneyline .sports-moneyline_tournament-name-wrapper {
  background-color: $background-color__sports-moneyline_Tournament-Name;
  color: $color__sports-moneyline_Tournament-Name;
}

.sports-moneyline_tournament-name {
  padding: 4px 10px;
  font-size: 14px;
}

.sports-moneyline-league-title {
  display: flex;
  align-items: center;
  height: 32px;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  background-color: #303030;
}
.sports-moneyline-league-title span {
  padding-left: 33px;
  flex: auto;
}
.sports-moneyline-league-title select {
  margin-right: 19px;
  font-size: 13px;
  background-color: #bfbfbf;
}
.sports-moneyline_sportMiniTitle {
  display: flex;
  align-items: center;
  padding: 0 8px 0 10px;
  margin: 0;
  height: 28px;
  color: $color__sports-moneyline_sportMiniTitle;
  font-size: 12px;
  font-weight: 700;
  background-color: $background-color__sports-moneyline_sportMiniTitle;
}
.sports-moneyline_sportTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 23px;
  margin: 0;
  height: 44px;
  color: $color__sports-moneyline_sportTitle;
  font-size: 20px;
  font-weight: 700;
  background-color: $background-color__sports-moneyline_sportTitle;
}
.sports-moneyline_sportTitle .sports-moneyline-sportTitle-options {
  font-size: 0;
}
.sports-moneyline_sportTitle .sports-moneyline-sportTitle-options-btn {
  padding: 0 15px;
  height: 32px;
  margin-left: 7px;
  color: #303030;
  font-size: 12px;
  font-weight: 700;
  background-color: #dcdcdc;
  border-radius: 4px;
}
.sports-moneyline_orderOptions {
  padding-left: 11px;
}
.sports-moneyline_orderOptions .sports-moneyline_orderOption {
  width: 153px;
  border: 1px solid #707070;
  background: #fff;
  height: 28px;
  font-size: 13px;
  color: #919191;
}
.sports-moneyline_filters {
  padding-right: 19px;
}
.sports-moneyline_filters .sports-moneyline_filters-option {
  border: 0;
  height: 28px;
  margin-left: 8px;
  padding: 0 11px;
  color: #919191;
  background-color: #dfe5e5;
}

@media screen and (min-width: $screen-width-tablet-min-width) {
  .sports-moneyline {
      display: table;
      width: 100%;
  }

  .sports-moneyline .sports-moneyline_title {
      display: table-row;
      height: 22px;
      font-weight: 700;

      &.outhright_event_title{
        padding: 6px 0;
      }

      .sports-moneyline_oddsColumn_wrapper{
        border-bottom: $border-bottom__sports_moneyline_Title;
      }

      .sports-moneyline_pinToTop{
        border-bottom: $border-bottom__sports_moneyline_Title;
      }

      .sports-moneyline_link_wrapper {
        border-bottom: $border-bottom__sports_moneyline_Title;
      }
  }

  .sports-moneyline .sports-moneyline_tournament-name-wrapper {
    display: table-row;
  }

  .sports-moneyline .sports-moneyline_title .sports-moneyline_link_wrapper,
  //.sports-moneyline .sports-moneyline_title .sports-moneyline_eventType,
  .sports-moneyline .sports-moneyline_title .sports-moneyline_oddsColumn_wrapper {
      display: table-cell;
  }

  .sports-moneyline .sports-moneyline_title .sports-moneyline_oddsColumn{
      min-height: auto;
  }

  .sports-moneyline .sports-moneyline_game .sports-moneyline_link {
      display: flex;
      flex-grow: 1;
      color: #000;
      min-height: 52px;
  }

  .sports-moneyline .sports-moneyline_title .sports-moneyline_link,
  .sports-moneyline .sports-moneyline_title .sports-moneyline_eventType {
      display: flex;
      flex-grow: 1;
  }

  .sports-moneyline .sports-moneyline_game {
      display: table-row;
      background-color: #fff;
      margin-bottom: 1px;
      min-height: 52px;
  }

  .sports-moneyline .sports-moneyline_game .sports-moneyline_link_wrapper {
      display: table-cell;
      color: #000;
      min-height: 52px;
      vertical-align: middle;
      border-bottom: 1px solid #dfe5e5;
  }

  .sports-moneyline_oddsColumn_wrapper {
      display: table-cell;
      border-bottom: 1px solid #dfe5e5;
      vertical-align: middle;
      width: 56px;
  }

  .sports-moneyline_oddsColumn {
      display: flex;
      align-content: stretch;
      align-items: center;
      min-height: 52px;
      justify-content: center;
  }

  .sports-moneyline_pinToTop {
      width: 42px;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      border-bottom: 1px solid #dfe5e5;
  }
}

@media screen and (min-width: $screen-width-full-min-width) {
  .sports-moneyline_oddsColumn_wrapper {
    width: 123px;
  }
}

@media screen and (max-width: $screen-width-tablet-max-width) {
    .sports-moneyline .sports-moneyline_title {
        display: flex;
        height: 22px;
        align-items: center;
        font-weight: 700;

        &.outhright_event_title{
          padding: 6px 0;
        }
    }

    .sports-moneyline .sports-moneyline_title .sports-moneyline_link_wrapper{
        display: flex;
        flex-grow: 1;
    }

    .sports-moneyline .sports-moneyline_title .sports-moneyline_link,
    .sports-moneyline .sports-moneyline_title .sports-moneyline_eventType {
        display: flex;
        flex-grow: 1;
    }

    .sports-moneyline .sports-moneyline_game {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        margin-bottom: 1px;
        height: 52px;
    }

    .sports-moneyline .sports-moneyline_game .sports-moneyline_link_wrapper{
        display: flex;
        flex-grow: 1;
        align-self: stretch;
    }

    .sports-moneyline .sports-moneyline_game .sports-moneyline_link {
        display: flex;
        flex-grow: 1;
        color: #000;
        height: 52px;
    }

    .sports-moneyline_oddsColumn_wrapper{
        display: flex;
        align-self: stretch;
        padding: 10px 0;
    }

    .sports-moneyline_oddsColumn {
        padding-left: 8px;
        width: 162px;
        display: flex;
        align-self: stretch;
        align-items: center;
    }

    .sports-moneyline_pinToTop {
        width: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // ===============================================================
    .sports-moneyline_oddsColumn {
        padding-left: 0;
        width: 81px;
        flex-direction: column;
        text-align: center;
      }
      .sports-moneyline_matched,
      .sports-moneyline_pinToTop,
      .sports-moneyline_icons,
      .sports-moneyline_orderOptions,
      .sports-moneyline_filters {
        display: none;
      }
      .sports-moneyline .sports-moneyline_title {
        height: 36px;
        padding: 0;
        border-bottom: $border-bottom__sports_moneyline_Title;
      }
      .sports-moneyline .sports-moneyline_game {
        height: auto;
        /* padding: 10px; */
      }
      .sports-moneyline .sports-moneyline_game .sports-moneyline_time {
        border-radius: 0;
        overflow: hidden;
      }
      .sports-moneyline
        .sports-moneyline_game
        .sports-moneyline_time
        .sports-moneyline_time_row:first-child {
        padding-bottom: 5px;
      }
      .sports-moneyline .sports-moneyline_game .sports-moneyline_link {
        height: auto;
        min-height: 52px;
        align-self: stretch;
      }
      .sports-moneyline
        .sports-moneyline_game
        .sports-moneyline_link
        .sports-moneyline_eventName_row:first-child {
        padding-bottom: 5px;
      }
      .sports-moneyline
        .sports-moneyline_game
        .sports-moneyline_link
        .sports-moneyline_betCount,
      .sports-moneyline .sports-moneyline_game .sports-moneyline_matched {
        display: none;
      }
      .sports-moneyline .sports-selection_options-risk-options {
        display: none;
      }
      .sports-moneyline .sports-selection_options {
        padding: 10px 20px 17px;
      }
      .sports-moneyline .sports-selection_options .sports-selection_options-info {
        padding-left: 0;
        padding-bottom: 15px;
      }
      .sports-moneyline
        .sports-selection_options
        .sports-selection_options-info
        br {
        display: none;
      }
      .sports-moneyline
        .sports-selection_options
        .sports-selection_options-controls {
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;
        height: auto;
        align-items: flex-start;
        grid-gap: 7px 0;
        padding: 0;
      }
      .sports-moneyline
        .sports-selection_options
        .sports-selection_options-controls
        > * {
        border-radius: 0;
        height: 32px;
        margin: 0;
      }
      .sports-moneyline
        .sports-selection_options
        .sports-selection_options-controls
        .sports-selection_options-stake {
        width: auto;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
      }
      .sports-moneyline
        .sports-selection_options
        .sports-selection_options-controls
        .sports-selection_options-cancel {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 2;
        grid-row-end: 2;
      }
      .sports-moneyline
        .sports-selection_options
        .sports-selection_options-controls
        .sports-selection_options-cancel,
      .sports-moneyline
        .sports-selection_options
        .sports-selection_options-controls
        .sports-selection_options-bet,
      .sports-moneyline
        .sports-selection_options
        .sports-selection_options-controls
        .sports-selection_options-place-bet {
        padding: 0;
        font-size: 13px;
        font-weight: 700;
      }
      .sports-moneyline
        .sports-selection_options
        .sports-selection_options-controls
        .sports-selection_options-stake,
      .sports-moneyline
        .sports-selection_options
        .sports-selection_options-controls
        .sports-selection_options-cancel {
        margin-right: 6px;
      }
      .sports-moneyline
        .sports-selection_options
        .sports-selection_options-controls
        .sports-selection_options-bet,
      .sports-moneyline
        .sports-selection_options
        .sports-selection_options-controls
        .sports-selection_options-place-bet {
        margin-left: 6px;
      }
      .live-game.score-type-sets .sports-moneyline_time_inner {
        padding: 17px 0;
      }
      .sports-moneyline_sportMiniTitle {
        height: 38px;
      }
      .sports-moneyline-league-title span {
        padding-left: 23px;
      }
      .sports-moneyline-league-title select {
        display: none;
      }
}
