.tabsMenu {
  display: flex;
  align-self: stretch;
  align-items: center;
  height: 35px;
  font-size: 0;
  background-color: #FFF;
}
.tabsMenu a.tabsMenu-tab {
  display: block;
  position: relative;
  padding: 0 16px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  color: #000;
  font-size: 12px;
  font-weight: 700;
  border-right: 1px solid #bfbfbf;
  white-space: nowrap;
  flex-grow: 1;
  text-align: center;
  border-bottom: 1px solid $background-color__mainContent;
}
.tabsMenu a.tabsMenu-tab.selected {
  background-color: $background-color__betslip_tab_active;
  color: $color__betslip_tab_active;
}
.tabsMenu a.tabsMenu-tab.selected:after {
  content: '';
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  left: 0;
  top: 0;
  background-color: $background-color__betslip_tab_active_accent;
}