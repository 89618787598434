$logo-img-path: './../../imgs/logo-main.svg';
$login-logo-img-path: './../../imgs/logo-login.svg';

//header
$background-color__mainHeader: $theme-bg-color-1;
$color__mainHeader: $theme-text-color-1;
$background-color__subHeader: $theme-bg-color-5;
$color__subHeader: $theme-text-color-5;
$background-color__mainHeader_link__active: $theme-bg-color-14;
$color__mainHeader_link__active: $theme-text-color-14;
$background-color__subHeader_link__active: $theme-bg-color-17;
$color__subHeader_link__active: $theme-text-color-17;
$border-color__subHeader_link: $theme-bg-color-18;

$background-color__quick-login-btn: $theme-bg-color-6;
$color__quick-login-btn: $theme-text-color-6;
$background-color__quick-login-toggle: $theme-bg-color-1-active;
$color__quick-login-toggle: $theme-text-color-1-active;
$background-color__quick-login-form: $theme-bg-color-1-active;
$color__quick-login-form: $theme-text-color-1-active;
$background-color__quick-login-mobile-btn: $theme-bg-color-1;
$color__quick-login-mobile-btn: $theme-text-color-1;

//login
$background-color__login-block-content: $theme-bg-color-3;
$background-image__loginPage: './../../imgs/bgr-login.jpg';
$background-color__login-button: $theme-bg-color-5;
$color__login-button: $theme-text-color-5;
$background-image_login-block-content: url('./../../imgs/login-form-bg_image.png');
$color__login-status-message: $theme-text-color-14;
$background-color__login-status-message: $theme-bg-color-12;
$padding__login-status-message: 4px 10px;

// main content
$background-color__mainContent: $theme-bg-color-15;
$color__mainContent: $theme-text-color-15;
$background-image__mainContent: none;
$border-color__mainContent: $theme-bg-color-4;

$background-color__leftContent: $theme-bg-color-3;
$background-image__leftContent: none;

// topsports
$background-color__sports-moneyline_Title: #DFDFDF;
$color__sports-moneyline_Title: #000;
$background-color__sports-moneyline--ouright_Title: #FFF;
$color__sports-moneyline--outright_Title: #000;
$border-bottom__sports_moneyline_Title: none;

$background-color__sports-moneyline_Tournament-Name: $theme-bg-color-6;
$color__sports-moneyline_Tournament-Name: $theme-text-color-6;

$background-color__sports-moneyline_sportTitle: $theme-bg-color-6;
$color__sports-moneyline_sportTitle: $theme-text-color-6;

$background-color__sports-moneyline_sportMiniTitle: $theme-bg-color-14;
$color__sports-moneyline_sportMiniTitle: $theme-text-color-14;

$backround-color__sports_moneyline_Time: $theme-bg-color-13;
$color__sports_moneyline_Time: $theme-text-color-13;
$backround-color__sports_moneyline_Time__Live: $theme-bg-color-11;
$color__sports_moneyline_Time__Live: $theme-text-color-11;

//left
$background-color__sports-list-item_sport-title: $theme-bg-color-6;
$color__sports-list-item_sport-title: $theme-text-color-6;
$border-bottom__sports-list-item_sport-title: 1px solid $theme-bg-color-4;
$font-weight__sports-list-item_sport-title: normal;

$background-color__left_h3: $theme-bg-color-6;
$color__left_h3: $theme-text-color-6;

$background-color__sports-list-item__hover: $theme-bg-color-13;
$color__left_sports_list_item: $theme-text-color-16;
$color__left_sports_list_item__selected: $theme-text-color-15;
$color__left_sports_list_item__expanded: $theme-text-color-15;
$border__left_sports_list_item: 1px solid $theme-bg-color-4;

//bet buttons
$background-color__bet-button-back: $theme-bg-color-8;
$background-color__bet-button-back__active: $theme-bg-color-8-active;

$background-color__bet-button-lay: $theme-bg-color-9;
$background-color__bet-button-lay__active: $theme-bg-color-9-active;

$background-color__bet-button-secondary-level: $theme-bg-color-3;
$background-color__bet-button-secondary-level__active: $theme-bg-color-2;

//contact form 
$background-color__contact-form-sumit-btn: $theme-bg-color-1;
$color__contact-form-sumit-btn: $theme-text-color-1;

$background-color__contact-form-sumit-btn__active: $theme-bg-color-1-active;
$color__contact-form-sumit-btn__active: $theme-text-color-1-active;

// betslip
$background-color__betslip_tab_active: $theme-bg-color-1;
$color__betslip_tab_active: $theme-text-color-1;
$background-color__betslip_tab_active_accent: $theme-bg-color-1;
$color__betslip_empty_message: $theme-text-color-15;

//paginator
$color__top-events-paginator-wrapper: $theme-text-color-3;
$border-color__top-events-paginator-item-container: $color__top-events-paginator-wrapper;
$background-color__top-events-paginator-page: $theme-bg-color-3;
$border-color__top-events-paginator-page: $theme-bg-color-4;
$color__top-events-paginator-page: $theme-text-color-3;

$background-color__top-events-paginator-page_active: $theme-bg-color-1;
$color__top-events-paginator-page_active: $theme-text-color-1;

$background-color__top-events-paginator-page_hover: $theme-bg-color-1-active;
$color__top-events-paginator-page_hover: $theme-text-color-1-active;

// favorites page
$background-color__favorites-sport-name: $theme-bg-color-4;
$color__favorites-sport-name: $theme-text-color-4;
$background-color__favorites-page-items-wrapper: $theme-bg-color-3;
$color__favorites-page-items-wrapper: $theme-text-color-3;

/*
 *
 *
 * ICONS VARIABLES
 *
 *
 */

 $root-icons-path: '/imgs/icons/' + $icons-set;
 
 //login
 $icon-login-user-path: url("#{$root-icons-path}/#{$icons-login-color}/login/user.svg");
 $icon-login-password-path: url("#{$root-icons-path}/#{$icons-login-color}/login/password.svg");
 
 //sports
 $icon-sport-icon-default-path: url("#{$root-icons-path}/#{$icons-sport-default}/mainmenu/icon-exchange.svg");
 $icon-sport-icon-default-active-path: url("#{$root-icons-path}/#{$icons-sport-active}/mainmenu/icon-exchange.svg");
 
 $icon-sport-icon-soccer-path: url("#{$root-icons-path}/#{$icons-sport-default}/sports/soccer.svg");
 $icon-sport-icon-soccer-active-path: url("#{$root-icons-path}/#{$icons-sport-active}/sports/soccer.svg");
 
 $icon-sport-icon-tennis-path: url("#{$root-icons-path}/#{$icons-sport-default}/sports/tennis.svg");
 $icon-sport-icon-tennis-active-path: url("#{$root-icons-path}/#{$icons-sport-active}/sports/tennis.svg");

 $icon-sport-icon-basketball-path: url("#{$root-icons-path}/#{$icons-sport-default}/sports/basketball.svg");
 $icon-sport-icon-basketball-active-path: url("#{$root-icons-path}/#{$icons-sport-active}/sports/basketball.svg");

 $icon-sport-icon-motorsports-path: url("#{$root-icons-path}/#{$icons-sport-default}/sports/motorsports.svg");
 $icon-sport-icon-motorsports-active-path: url("#{$root-icons-path}/#{$icons-sport-active}/sports/motorsports.svg");
 
 $icon-sport-icon-horseracing-path: url("#{$root-icons-path}/#{$icons-sport-default}/sports/horseracing.svg");
 $icon-sport-icon-horseracing-active-path: url("#{$root-icons-path}/#{$icons-sport-active}/sports/horseracing.svg");
 
 $icon-sport-icon-greyhounds-path: url("#{$root-icons-path}/#{$icons-sport-default}/sports/greyhounds.svg");
 $icon-sport-icon-greyhounds-active-path: url("#{$root-icons-path}/#{$icons-sport-active}/sports/greyhounds.svg");

 $icon-sport-icon-cricket-path: url("#{$root-icons-path}/#{$icons-sport-default}/sports/cricket.svg");
 $icon-sport-icon-cricket-active-path: url("#{$root-icons-path}/#{$icons-sport-active}/sports/cricket.svg");

 $icon-sport-icon-baseball-path: url("#{$root-icons-path}/#{$icons-sport-default}/sports/baseball.svg");
 $icon-sport-icon-baseball-active-path: url("#{$root-icons-path}/#{$icons-sport-active}/sports/baseball.svg");

 // menus
 $icon-menu-exchange-path: url("#{$root-icons-path}/#{$icons-menu-default}/mainmenu/icon-exchange.svg");
 $icon-menu-exchange-active-path: url("#{$root-icons-path}/#{$icons-menu-active}/mainmenu/icon-exchange.svg");

 $icon-menu-live-path: url("#{$root-icons-path}/#{$icons-menu-default}/mainmenu/icon-live.svg");
 $icon-menu-live-active-path: url("#{$root-icons-path}/#{$icons-menu-active}/mainmenu/icon-live.svg");

 $icon-menu-search-path: url("#{$root-icons-path}/#{$icons-menu-default}/mainmenu/icon-search.svg");
 $icon-menu-search-active-path: url("#{$root-icons-path}/#{$icons-menu-active}/mainmenu/icon-search.svg");

 $icon-menu-my-markets-path: url("#{$root-icons-path}/#{$icons-menu-default}/mainmenu/icon-my-markets.svg");
 $icon-menu-my-markets-active-path: url("#{$root-icons-path}/#{$icons-menu-active}/mainmenu/icon-my-markets.svg");

 // stickymenu
 $icon-stickymenu-home-path: url("#{$root-icons-path}/#{$icons-menu-default}/stickymenu/icon-home.svg");
 $icon-stickymenu-home-active-path: url("#{$root-icons-path}/#{$icons-menu-active}/stickymenu/icon-home.svg");

 $icon-stickymenu-favorites-path: url("#{$root-icons-path}/#{$icons-menu-default}/favorites/favorite-menu.svg");
 $icon-stickymenu-favorites-active-path: url("#{$root-icons-path}/#{$icons-menu-active}/favorites/favorite-menu.svg");

 $icon-stickymenu-back-path: url("#{$root-icons-path}/#{$icons-menu-default}/stickymenu/icon-back.svg");
 $icon-stickymenu-back-active-path: url("#{$root-icons-path}/#{$icons-menu-active}/stickymenu/icon-back.svg");

 $icon-stickymenu-mybet-path: url("#{$root-icons-path}/#{$icons-menu-default}/stickymenu/icon-mybet.svg");
 $icon-stickymenu-mybet-active-path: url("#{$root-icons-path}/#{$icons-menu-active}/stickymenu/icon-mybet.svg");
 
 $icon-stickymenu-account-seamless-path: url("#{$root-icons-path}/#{$icons-menu-default}/stickymenu/icon-account_seamless.svg");
 $icon-stickymenu-account-seamless-active-path: url("#{$root-icons-path}/#{$icons-menu-active}/stickymenu/icon-account_seamless.svg");
 
 $icon-stickymenu-favourite-path: url("#{$root-icons-path}/#{$icons-menu-default}/stickymenu/icon-favourite.svg");
 $icon-stickymenu-favourite-active-path: url("#{$root-icons-path}/#{$icons-menu-active}/stickymenu/icon-favourite.svg");

 $icon-stickymenu-cashout-path: url("#{$root-icons-path}/#{$icons-menu-default}/stickymenu/icon-cashout.svg");
 $icon-stickymenu-cashout-active-path: url("#{$root-icons-path}/#{$icons-menu-active}/stickymenu/icon-cashout.svg");

 $icon-stickymenu-casino-path: url("#{$root-icons-path}/#{$icons-menu-default}/stickymenu/icon-casino.svg");
 $icon-stickymenu-casino-active-path: url("#{$root-icons-path}/#{$icons-menu-active}/stickymenu/icon-casino.svg");

 // header
 $icon-header-user-mobile-path: url("#{$root-icons-path}/#{$icons-color-1}/header/user-mobile.svg");
 $icon-header-user-path: url("#{$root-icons-path}/#{$icons-color-1}/header/user.svg");
 $icon-header-user-path-mobile: url("#{$root-icons-path}/#{$icons-color-7}/header/user.svg");
 $icon-header-arrow-dropdown-path: url("#{$root-icons-path}/#{$icons-color-7}/header/arrow_dropdown.svg");
 $icon-header-quick-login-path: url("#{$root-icons-path}/#{$icons-color-1}/header/user.svg");

 // events
 $icon-events-live-icon-path: url("#{$root-icons-path}/#{$icons-live-color}/events/live-icon.svg");
 $icon-events-video-icon-path: url("#{$root-icons-path}/#{$icons-live-color}/events/video-icon.svg");
 $icon-events-all-sports-path: url("#{$root-icons-path}/#{$icons-live-color}/events/all-sports-link.svg");

 // betslip
 $icon-betslip-remove-path: url("#{$root-icons-path}/#{$icons-quick-betslip-color}/betslip/remove-icon.svg");
 $icon-betslip-back-path: url("#{$root-icons-path}/#{$icons-quick-betslip-color}/betslip/backspace-icon.svg");
 $icon-virtual-kbd-remove-path: url("#{$root-icons-path}/#{$icons-quick-betslip-color}/betslip/vkbd_remove-icon.svg");


 // arrows
 $icon-arrows-down-full-path: url("#{$root-icons-path}/#{$icons-color-1}/arrows/arrow-down_full_black.svg");
 $icon-arrows-up-full-path: url("#{$root-icons-path}/#{$icons-color-1}/arrows/arrow-up_full_black.svg");
 $icon-arrows-down--black-full-path: url("#{$root-icons-path}/#{$icons-color-6}/arrows/arrow-down_full_black.svg");
 $icon-arrows-up--black-full-path: url("#{$root-icons-path}/#{$icons-color-6}/arrows/arrow-up_full_black.svg");

 // search
 $icon-search-path: url("#{$root-icons-path}/#{$icons-search-color}/search/search-icon.svg");
 $icon-search-path--mobile-header: url("#{$root-icons-path}/#{$icons-search-mobile-color}/search/search-icon.svg");

 // left
 $icon-left-arrow-close-path: url("#{$root-icons-path}/#{$icons-color-1}/left/arrow-close.svg");
 $icon-left-market-path: url("#{$root-icons-path}/#{$icons-color-1}/left/market-icon.svg");

 // media
 $icon-media-livetracker-path: url("#{$root-icons-path}/#{$icons-color-1}/media/livetracker.svg");

 //favorites
 $icon-events-favorites-path: url("#{$root-icons-path}/#{$icons-fav-color}/favorites/favorite-add.svg");
 $icon-events-favorites-hover-path: url("#{$root-icons-path}/#{$icons-fav-color-act}/favorites/favorite-add.svg");
 $icon-events-favorites-active-path: url("#{$root-icons-path}/#{$icons-fav-color-act}/favorites/favorite-remove.svg");
 $icon-events-favorites-active-hover-path: url("#{$root-icons-path}/#{$icons-fav-color}/favorites/favorite-remove.svg");
 $icon-events-favorites-progress-path: url("#{$root-icons-path}/#{$icons-fav-color}/favorites/favorite-loading.svg");

 $icon-market-favorites-path: url("#{$root-icons-path}/#{$icons-market-fav-color}/favorites/favorite-add.svg");
 $icon-market-favorites-active-hover-path: url("#{$root-icons-path}/#{$icons-market-fav-color}/favorites/favorite-remove.svg");
 $icon-market-favorites-progress-path: url("#{$root-icons-path}/#{$icons-market-fav-color}/favorites/favorite-loading.svg");
