.quick-betslip-wrapper{
    position: relative;

    .quick-betslip-fader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1;

        .loading-spinner-container {
            top: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
        }
    }

    &.bet-type-back{
        background-color: lighten($theme-bg-color-8, 10);
    }

    &.bet-type-lay{
        background-color: lighten($theme-bg-color-9, 5);
    }

    .quick-betslip-options-container{
        display: flex;
        padding-top: 10px;
        gap: 10px;
        align-items: center;

        & > div {
            flex: 1 50%;
        }

        .ui-el-bet-odds-values-item--wrapper{
            .ui-el-bet-odds-values-item{
                height: 30px;
                line-height: 30px;
                font-size: 12px;
                padding: 0 30px;
                text-align: center;
                width: 100%;
            }
    
            .ui-el-bet-odds-values-item--arrows-container{
                position: static;
                width: 0;
                height: 0;

                .ui-el-bet-odds-values-item--arrow{
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    top: 0;
                    background-color: #DCDCDC;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 40% auto;

                    &.ui-el-bet-odds-values-item--arrow-down{
                        background-image: $icon-arrows-down-full-path;
                        left: 0;
                    }

                    &.ui-el-bet-odds-values-item--arrow-up{
                        background-image: $icon-arrows-up-full-path;
                        right: 0;
                    }
                }
            }

        }

        .ui-el-bet-stake-values-item--wrapper{
            position: relative;

            .ui-el-bet-stake-values-item{
                height: 30px;
                line-height: 30px;
                font-size: 12px;
                padding: 0 30px;
                text-align: center;
                width: 100%;
            }
    
            .ui-el-bet-stake-values-item--arrows-container{
                position: static;
                width: 0;
                height: 0;

                .ui-el-bet-stake-values-item--arrow{
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    top: 0;
                    background-color: #DCDCDC;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 40% auto;

                    &.ui-el-bet-stake-values-item--arrow-down{
                        left: 0;
                        background-image: $icon-arrows-down-full-path;
                    }

                    &.ui-el-bet-stake-values-item--arrow-up{
                        right: 0;
                        background-image: $icon-arrows-up-full-path;
                    }
                }
            }

        }

        .focused {
            background-color: #EEEEEE;
            padding: 6px;
            border-radius: 6px 6px 0 0;
        }
    }

    .quick-betslip-buttons-container{
        display: flex;
        padding: 10px;
        gap: 10px;
        flex: 1 1 50%;

        .quick-betslip-button{
            height: 45px;
            background-color: $theme-bg-color-1;
            color: $theme-text-color-1;
            font-size: 12px;
            text-align: center;
            align-content: center;
            line-height: 45px;
            width: 100%;

            &.quick-betslip-cancel-button {
                background-color: #DCDCDC;
                color: #000;
            }

            &.disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }
}

.quick-betslip-result-wrapper{
    position: relative;

    .quick-betslip-result-container{
        padding: 10px 40px 10px 10px;

        &.success{
            background-color: $theme-bg-color-11;
            color: $theme-text-color-11;
        }

        &.fail{
            background-color: $theme-bg-color-12;
            color: $theme-text-color-12;
        }
    }

    .quick-betslip-result-close{
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.8);
        background-image: $icon-virtual-kbd-remove-path;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 15px;
    }
}

/*
 *
 * QUICK BETSLIP RELATED POPUPS
 *
 *
*/

.quick-betslip-confirm-popup-wrapper {
    min-width: 270px;

    .quick-betslip-confirm-popup-title {
        padding: 20px;
        font-size: 1.1rem;
        background-color: $theme-bg-color-4;
    }

    .quick-betslip-confirm-popup-bet-details-container{
        &.bet-type-back {
            background-color: $theme-bg-color-8-inactive;
            color: $theme-text-color-8-inactive;
        }
        &.bet-type-lay {
            background-color: $theme-bg-color-9-inactive;
            color: $theme-text-color-9-inactive;
        }
        padding: 20px;

        .quick-betslip-confirm-popup-bet-info {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid $theme-bg-color-3;
        }

        .quick-betslip-confirm-popup-bet-details {
            display: flex;

            .quick-betslip-confirm-popup-bet-odds {
                border-left: 1px solid $theme-bg-color-3;
                border-right: 1px solid $theme-bg-color-3;
                margin: 0 20px;
                padding: 0 20px;
            }

            .qbcp-qtty {
                font-size: 1.2rem;
                text-align: center;
            }
        }
    }

    .quick-betslip-confirm-popup-buttons-container {
        margin-top: 20px;
        display: flex;
        gap: 20px;
        align-items: stretch;

        .quick-betslip-confirm-popup-cancel-btn {
            height: 40px;
            width: 100%;
            text-align: center;
            background-color: $theme-bg-color-12;
            color: $theme-text-color-12;
            justify-content: center;
            display: flex;
            align-items: center;
        }

        .quick-betslip-confirm-popup-confirm-btn {
            height: 40px;
            width: 100%;
            text-align: center;
            background-color: $theme-bg-color-1;
            color: $theme-text-color-1;
            justify-content: center;
            display: flex;
            align-items: center;
        }
    }
}

.quick-betslip-process-popup-wrapper {
    min-width: 270px;
    
    .quick-betslip-process-popup-spinner {
        padding: 40px 20px;
    }
}