.sports-moneyline_bet-btn {
    font-size: 13px;
    min-width: 61px;
    align-self: stretch;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.back {
        background-color: $background-color__bet-button-back;

        &:hover,
        &.selectedOdd {
            background-color: $background-color__bet-button-back__active;
        }

        &.layout-mobile:hover{
            background-color: $background-color__bet-button-back;

            &.selectedOdd {
                background-color: $background-color__bet-button-back__active;
            }
        }

        &.secondary-level-bet{
            background-color: $background-color__bet-button-secondary-level;
            border: 1px solid $background-color__bet-button-secondary-level__active;

            &:hover,
            &.selectedOdd{
                background-color: $background-color__bet-button-secondary-level__active;
            }
        }
    }

    &.lay {
        background-color: $background-color__bet-button-lay;

        &:hover,
        &.selectedOdd {
            background-color: $background-color__bet-button-lay__active;
        }

        &.layout-mobile:hover{
            background-color: $background-color__bet-button-lay;

            &.selectedOdd{
                background-color: $background-color__bet-button-lay__active;
            }
        }

        &.secondary-level-bet{
            background-color: $background-color__bet-button-secondary-level;
            border: 1px solid $background-color__bet-button-secondary-level__active;

            &:hover,
            &.selectedOdd{
                background-color: $background-color__bet-button-secondary-level__active;
            }
        }
    }

    .sports-moneyline_bet-btn_row{
        font-size: 10px;
    }

    .sports-moneyline_bet-btn_row:first-child {
        font-weight: 700;
        font-size: 12px;
    }
}

.market-options-wrapper .sports-moneyline_bet-btn{
    margin: 0 0 1px 1px;
}

@media screen and (min-width:$screen-width-tablet-min-width) and (max-width: $screen-width-full-max-width) {
    .sports-moneyline_bet-btn{
        min-width: 50px;
        min-height: 33px;
    }
}

@media screen and (min-width:$screen-width-tablet-min-width) and (max-width: $screen-width-full-max-width){
    .sports-moneyline_bet-btn{
        width: 55px;
    }
}

@media screen and (max-width: $screen-width-tablet-max-width) {
    .sports-moneyline_bet-btn {
        min-width: 50px;
        min-height: 33px;
        margin: 0 5px 10px 5px;
        border-radius: 3px;
        &.top-market-event-view-bet-btn {
            min-width: 65px;
        }
    }
      .sports-moneyline_bet-btn:last-child {
        margin-bottom: 0;
    }
}

@media screen and (max-width: $screen-width-mobile-max-width) {
    .sports-moneyline_bet-btn{
        width: 40px;
        font-size: 11px;
        &.top-market-event-view-bet-btn{
            width: 65px;
        }
    }
}