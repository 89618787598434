.search_wrapper {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  color: #000;
}
.search_wrapper::before {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  right: 29px;
  width: 1px;
  height: 24px;
  background-color: #707070;
}
.search_wrapper .search_field {
  width: 100%;
  height: 28px;
  box-sizing: border-box;
  text-indent: 8px;
  line-height: 28px;
}
.search_wrapper .search_field::placeholder {
  color: #9b9b9b;
}
.search_wrapper .search_button {
  display: block;
  width: 21px;
  height: 21px;
  top: 3px;
  right: 3px;
  position: absolute;
  background: transparent $icon-search-path no-repeat
    center center;
}
.search_wrapper .search_button.inactive{
  opacity: 0.5;
  pointer-events: none;
}

.search-page-info-text{
  padding: 10px 20px 20px;
}
.search-results-table-container{
  padding: 30px 0;
}

.search-results-table-container a{
  color: #3070de;
  text-decoration: underline;
}

.search-results-table-container a:hover{
  font-weight: bold;  
}

.header_search{
  position: relative;
}

.header_search_results_container{
  position: absolute;
  top: 42px;
  background-color: $theme-bg-color-3;
  color: $theme-text-color-3;
  padding: 7px 10px;
  border-radius: 0 0 5px 5px;
  z-index: 999;

  .header_search_result{
    padding: 3px 0;
    border-bottom: 1px solid #ededed;
    font-size: 0.9rem;
  }
}

@media screen and (min-width:$screen-width-full-min-width) {
  .search_block.header_search{
    width: 380px;
    justify-self: center;
    align-self: center;
    grid-area: span 2 / span 1;
  }
}

@media screen and (min-width:$screen-width-tablet-min-width) and (max-width:$screen-width-full-max-width) {
  .search_block.header_search{
    width: 380px;
    justify-self: center;
    align-self: center;
    grid-area: span 2 / span 1;
  }
}

@media screen and (min-width:$screen-width-mobile-min-width) and (max-width:$screen-width-tablet-max-width) {
  .search_block.header_search{
    width: 220px;
    justify-self: center;
    align-self: center;
    grid-area: span 2 / span 1;
  }
}

@media screen and (max-width: $screen-width-mobile-max-width) {
  .header.top-menu-on .search_block {
    .search_wrapper{
        width: 50px;
        height: 50px;
        border-radius: 6px;
        margin: 4px;
        background-color: $theme-bg-color-1-active;

        &:before{
            display: none;
        }

        .search_button{
            width: 32px;
            height: 32px;
            background-size: contain;
            margin: 6px;
            background-image: $icon-search-path--mobile-header;

            &.inactive{
                opacity: 1;
                pointer-events: all;
            }
        }
    }
    .search_field{
        display: none;
    }
  }

  .header.no-top-menu .search_block {
    position: absolute;
    left: 10px;
    top: 74px;
    width: 96%;
  }

  .header_search_results_container{
    display: none;
  }
}
