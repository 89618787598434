.markets-list-mobile-tabs-wrapper{
    padding: 5px;
    background-color: #444;
    max-width: 100vw;

    .markets-list-mobile-tabs-container{
        overflow: hidden;
        overflow-x: auto;
        .markets-list-mobile-tabs{
            display: flex;
            width: max-content;

            .markets-list-mobile-tab{
                color: #FFF;
                border-left: 1px solid #CCC;
                white-space: nowrap;

                &.all-markets-tab {
                    border: none;
                    position: sticky;
                    left: 0;
                    background-color: $theme-bg-color-1;
                    color: $theme-text-color-1;
                    
                    &.active{
                        background-color: $theme-bg-color-1-active;
                        color: $theme-text-color-1-active;

                        a {
                            color: $theme-text-color-1-active;
                        }
                    }

                    a {
                        color: $theme-text-color-1;
                    }
                }

                &.active {
                    background-color: #CCC;
                    color: #000;

                    a{
                        color: #000;
                    }
                }

                a{
                    text-decoration: none;
                    color: #FFF;
                    padding: 10px 5px;
                    display: block;
                }
            }
        }
    }

    
}