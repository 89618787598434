.stickyMenu {
  display: flex;
  position: fixed;
  width: 100%;
  height: 70px;
  bottom: 0;
  background-color: #6e6e6e;
  justify-content: space-between;
  z-index: 9999;
}
.stickyMenu .stickyMenu-item {
  color: #9b9b9b;
  padding: 0 5%;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stickyMenu .stickyMenu-item.active {
  background-color: #FFF;
}
.stickyMenu .stickyMenu-icon {
  margin-bottom: 5px;
  margin-top: 13px;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.sticky-icon-home {
  background-image: $icon-stickymenu-home-path;
}
.active .sticky-icon-home {
  background-image: $icon-stickymenu-home-active-path;
}
.sticky-icon-mybet {
  background-image: $icon-stickymenu-mybet-path;
}
.active .sticky-icon-mybet {
  background-image: $icon-stickymenu-mybet-active-path;
}
.user-icon-mobile-seamless {
  background-image: $icon-stickymenu-account-seamless-path;
}
.active .user-icon-mobile-seamless {
  background-image: $icon-stickymenu-account-seamless-active-path;
}
.sticky-icon-favourite {
  background-image: $icon-stickymenu-favourite-path;
}
.active .sticky-icon-favourite {
  background-image: $icon-stickymenu-favourite-active-path;
}
.sticky-icon-cashout {
  background-image: $icon-stickymenu-cashout-path;
}
.active .sticky-icon-cashout {
  background-image: $icon-stickymenu-cashout-active-path;
}
.sticky-icon-casino {
  background-image: $icon-stickymenu-casino-path;
}
.active .sticky-icon-casino {
  background-image: $icon-stickymenu-casino-active-path;
}