.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-container{
        margin: 5vh auto;
        padding: 20px;
        background-color: #FFF;
        border-radius: 20px;
        position: relative;
    }

    .modal-close-wrapper{
        position: absolute;
        right: -8px;
        top: -8px;
        width: 30px;
        height: 30px;
        background-color: #e5e5e5;
        border-radius: 30px;
        border: 1px solid $theme-bg-color-4;
        overflow: hidden;

        .modal-close-button{
            background-image: $icon-virtual-kbd-remove-path;
            width: 30px;
            height: 30px;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
        }
    }

    &.modal-wrapper-seamless {
        .modal-container {
            position: absolute;
        }
    }
}