.loginPage{
    background-image: url($background-image__loginPage);
    background-color: #e7ebee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 660px;
    height: 100vh;

    .login-block {
        color: #fff;
        font-size: 16px;
        height: 100%;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .login-block-wrapper {
            margin: 0 auto;
            width: 600px;

            &.hidden {
              display: none;
            }

            .login-block-content {
                background-color: $background-color__login-block-content;
                border-radius: 30px;
                box-sizing: border-box;
                padding: 68px;
                background-image: $background-image_login-block-content;
                background-repeat: no-repeat;
                background-position: top;
                background-size: 100% auto;

                .login-logo {
                    height: 67px;
                    width: 100%;
                    margin-bottom: 43px;
                    background-image: url($login-logo-img-path);
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                .login-field {
                    width: 100%;
                    height: 68px;
                    box-sizing: border-box;
                    padding-left: 66px;
                    border: 1px solid #707070;
                    border-radius: 6px;
                    color: #000;
                    font-size: 24px;
                    font-weight: 700;
                    background-color: #fff;
                    background-repeat: no-repeat;
                    background-position: 19px;

                    &:focus {
                        border-color: $theme-bg-color-1;
                    }

                    &::placeholder {
                        color: #000;
                    }

                    &.login-username {
                        margin-bottom: 28px;
                        background-image: $icon-login-user-path;
                        background-size: 30px;
                    }

                    &.login-password {
                        margin-bottom: 8px;
                        background-image: $icon-login-password-path;
                    }
                }

                .login-button {
                    height: 67px;
                    width: 100%;
                    margin: 22px 0 15px;
                    border-radius: 6px;
                    color: $color__login-button;
                    font-size: 24px;
                    font-weight: 700;
                    background-color: $background-color__login-button;

                    &:hover {
                        background-color: $theme-bg-color-1-active;
                        color: $theme-text-color-1-active;
                    }
                }

                .login-status-message {
                  color: $color__login-status-message;
                  background-color: $background-color__login-status-message;
                  padding: $padding__login-status-message;
                }
            }
        }
    }

    .login-page-contact-link {
      color: #000;
      display: block;
      cursor: pointer;
      text-align: right;
      margin-top: 10px;
      text-decoration: underline;

      &:hover {
        color: $theme-bg-color-1;
      }
    }
}

/*

.login-block-content a {
  color: #fff;
  font-weight: 700;
}
.login-block-content a:hover {
  color: $theme-bg-color-1;
}
.login-block-content .login-registration-link a {
  color: $theme-bg-color-1;
  display: inline-block;
  padding-right: 10px;
  background-repeat: no-repeat;
  background-position: right 1px;
  background-image: url('./../../imgs/icons/login/right-arrow.svg');
}
.login-block-content .login-registration-link a:hover {
  color: #fff;
}
.login-block-content .login-forgot-password,
.login-block-content .login-registration-link {
}
.login-block-content .login-forgot-password a,
.login-block-content .login-registration-link a {
}


@media screen and (max-width: $screen-width-tablet-max-width) {
  .login-block {
    font-size: 8px;
  }
  .login-block-wrapper {
    width: 85%;
    max-width: 300px;
  }
  .login-block-content {
    padding: 30px;
  }
  .login-block-content .login-field {
    height: 38px;
    font-size: 13px;
    background-size: 14px;
    padding-left: 35px;
    background-position: 12px;
  }
  .login-block-content .login-field.login-username {
    margin-bottom: 15px;
  }
  .login-block-content .login-button {
    height: 37px;
    font-size: 13px;
    margin: 15px 0 8px;
  }
  .login-block-content .login-registration-link a {
    background-size: 4px;
    padding-right: 6px;
  }
  .login-block-content .login-logo {
    height: 40px;
    margin-bottom: 23px;
  }
}
*/

@media screen and (max-width: $screen-width-tablet-max-width) {
    .loginPage{
    
        .login-block {
    
            .login-block-wrapper {
    
                .login-block-content {
                    
    
                    .login-logo {
                        
                    }
    
                    .login-field {
                        
    
                        &:focus {
                        }
    
                        &::placeholder {
                        }
    
                        &.login-username {
                           
                        }
    
                        &.login-password {
                            
                        }
                    }
    
                    .login-button {
                        
    
                        &:hover {
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $screen-width-mobile-max-width) {
    .loginPage{
    
        .login-block {
    
            .login-block-wrapper {
                width: 90%;
    
                .login-block-content {
                    padding: 30px;
    
                    .login-logo {
                        
                    }
    
                    .login-field {
                        font-size: 16px;
    
                        &:focus {
                        }
    
                        &::placeholder {
                        }
    
                        &.login-username {
                           
                        }
    
                        &.login-password {
                            
                        }
                    }
    
                    .login-button {
                        
    
                        &:hover {
                        }
                    }
                }
            }
        }
    }
}