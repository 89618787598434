.hr-top-events-header-wrapper {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 0 0 10px 0;
    background-color: $background-color__sports-moneyline_sportMiniTitle;
    justify-content: space-between;

    .hr-top-events-heading{
        color: $color__sports-moneyline_sportMiniTitle;
        font-size: 14.4px;
        font-weight: 700;
    }

    .days-filter-wrapper{
        display: flex;
        gap: 6px;

        .days-filter-item{
            padding: 3px 6px;
            background-color: #bfbfbf;
            cursor: pointer;

            &.selected{
                background-color: #FFF;
                cursor: auto;
            }
        }
    }
}

.tabs-switcher-tabs-container{
    display: flex;

    .tabs-switcher-tab{
        padding: 5px 10px;
        cursor: pointer;
        background-color: #e5e5e5;
        border: 1px solid #CCC;
        display: grid;
        align-items: center;
        grid-template-columns: auto auto;
        grid-template-rows: auto;
        grid-template-areas: 
            "icon icon"
            "name count";
        gap: 5px;

        &.selected{
            font-weight: bold;
            background-color: #FFF;
            border-bottom-color: #FFF;
            cursor: auto;
        }

        .tabs-switcher-tab-flag{
            grid-area: icon;
            justify-self: center;

            .coutry-flag-twemoji{
                width: 20px;
            }
        }
    }
}

.hr-top-events-event-wrapper{
    background-color: #FFF;
    padding: 0;
    margin-top: -1px;
    border: 1px solid #CCC;

    .hr-top-events-event-container{
        display: flex;
        border-bottom: 1px solid #dedede;
        padding: 10px;

        .hr-top-events-event-info-container{
            min-width: 40%;
            max-width: 40%;

            .hr-top-events-event-name{
                font-weight: bold;
            }
            
        }
    }

    .hr-top-events-markets-wrapper{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 10px 0;

        .hr-top-events-market{
            background-color: #e5e5e5;

            a{
                display: block;
                padding: 5px 10px;

                &:hover {
                    text-decoration: none;
                    background-color: #CCC;
                    color: #000;
                }
            }
        }
    }
}