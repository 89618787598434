body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: #000;
    font-family: $font-family-1;
    font-size: 13px;
}
:focus {
    outline: 0px solid transparent;
}
input,
button {
    font-family: $font-family-1;
    border: 0;
}
button {
    cursor: pointer;
}
a {
    text-decoration: none;
}
.text-negative {
    color: #bd4636;
}
.text-positive {
    color: #007a08;
}

.content-wrapper-inner {
  display: flex;
}
.content-left {
  width: 12%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 170px;
  background-color: $background-color__leftContent;
  background-image: $background-image__leftContent;
}
.content-main {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100vw;
  background-color: $background-color__mainContent;
  border-left: 1px solid $border-color__mainContent;
  border-bottom: 1px solid $border-color__mainContent;
  background-image: $background-image__mainContent;
}
.content-main .content-center {
  flex-grow: 1;
  padding: 18px;
}
.content-main .content-right {
  width: 30%;
  padding: 0;
  position: sticky;
  top: 0;
}
.center-block {
  margin-bottom: 11px;
}
.singleSport-block {
  flex-grow: 1;
}
.center-block-group .center-block {
  display: inline-flex;
  width: 33%;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0 9px;
  box-sizing: border-box;
}
.center-block-group .center-block:first-child {
  padding-left: 0;
}
.center-block-group .center-block:last-child {
  padding-right: 0;
}
.singleSport-show-all {
  display: flex;
  background-color: #fff;
  margin-top: 2px;
  justify-content: flex-end;
}
.singleSport-show-all a {
  display: inline-block;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 20px;
  line-height: 32px;
  color: #000;
  font-size: 14px;
}
.singleSport-show-all a:after {
  content: '';
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  right: 5px;
  top: 10px;
  background: transparent $icon-events-all-sports-path no-repeat
    center center;
}

.center-block.missing-sports-data-message{
  padding: 10px;
  background-color: $theme-bg-color-12;
  color: $theme-text-color-12;
  font-size: 11px;
}

@media screen and (max-width: $screen-width-tablet-max-width) {
  body {
    min-width: unset;
  }
  .desktopOnly {
    display: none !important;
  }
  .content-wrapper-inner {
    flex-direction: column;
  }
  .center-block-group .center-block {
    display: inline-flex;
    width: 100%;
    flex: 0 0 auto;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
  }
  .content-main .content-center {
    padding: 0;
  }
}

@media screen and (min-width: $screen-width-tablet-min-width) {
  .content-main{
    max-width: unset;
  }
  .content-main .content-right{
    min-width: 380px;
  }
  .mobileOnly {
      display: none !important;
  }
}