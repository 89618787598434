/*
  *
  *
  * PROGRESS WHEEL
  *
  *
  */

  .fader-wrapper{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .progress-wheel{
    width: 40px;
    height: 40px;
    border: 4px solid white;
    border-radius: 30px;
    border-top-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    animation-name: wheel;
    animation: 2s infinite;
    animation-name: wheel;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    animation-timing-function: linear;
  }

  /* The animation code */
  @keyframes wheel {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }

  .betslip-error-message,
  .bet-place-status-error {
    background-color: red;
    color: white;
    padding: 3px 15px;
  }

  .betslip-info-message{
    background-color: coral;
    color: #FFF;
    padding: 5px 10px;
  }

  .bet-place-status-error {
    border-bottom: 2px solid white;
  }

  .betslip-success-message{
    background-color: green;
    color: white;
  }

  .bet-place-status-success{
    background-color: green;
    color: white;
  }

  .bet-place-status-success-message{
    padding: 3px 15px;
  }

  .bet-place-status-success-progress-bar{
    background-color: yellowgreen;
    height: 5px;
    animation-name: progress;
    animation-iteration-count: 1;
    animation-duration: 2s;
    animation-timing-function: linear;    
  }

  /* The animation code */
  @keyframes progress {
    from {width: 0;}
    to {width: 100%;}
  }

  .betting-history-total-profit-loss{
    padding: 5px 25px;
  }

  .betting-history-table-wrapper{
    margin-top: 15px;
  }


/* 
 *
 *
 * Virtual KEYBOARD
 *
 *
 */

 .custom-virtual-kbd-wrapper{
  background-color: #FFF;
  padding: 5px 5px 10px 5px;
  border-bottom: 1px solid #FFF;

  &.mobile-view{
    background-color: #EEEEEE;
  }

  .cvkbd-quick-stake-buttons-container{
    display: flex;
    gap: 5px;
    justify-content: center;

    &.mobile-view{
      flex-wrap: nowrap;

      .cvkbd-quick-stake-button{
        height: 50px;
        line-height: 50px;
        background-color: #dcdcdc;
        color: #000;
      }
    }

    &.desktop-view{
      flex-wrap: wrap;

      .cvkbd-quick-stake-button{
        flex: 1 1 16%;
        cursor: pointer;
      }
    } 

    .cvkbd-quick-stake-button{
      line-height: 35px;
      height: 35px;
      font-size: 14px;
      background-color: $theme-bg-color-15;
      color: $theme-text-color-15;
      text-align: center;
      border-radius: 2px;
      flex: 1;
    }
  }
  .cvkbd-virtual-keyboard-container{
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    justify-content: center;
    padding-top: 4px;
    margin-top: 4px;
    border-top: 1px solid #CCC;

    .cvkbd-vkbd-numeric-buttons-container{
      display: grid;
      grid-template-columns: auto auto auto auto auto auto;
      flex: 1;
      gap: 5px;
    }

    .cvkbd-vkbd-functional-btns-container{
      display: grid;
      gap: 5px;
    }

    .cvkbd-vkbd-number-button{
      line-height: 35px;
      height: 35px;
      font-size: 1.1rem;
      background-color: rgba(255, 255, 255, 0.7);
      text-align: center;
      border-radius: 3px;
      flex: 1;

      &.disabled{
        opacity: 0.5;
        pointer-events: none;
      }

      &.backspace-btn{
        background-image: $icon-betslip-back-path;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 60%;
        min-width: 65px;
      }

      &.remove-btn{
        background-image: $icon-virtual-kbd-remove-path;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 60%;
        min-width: 65px;
      }
    }
  }
 }
