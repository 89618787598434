.header_width {
  width: 100%;
}
.header {
  width: 100%;
  position: relative;
}
.mainHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 84px;
  background-color: $background-color__mainHeader;
  color: $color__mainHeader;
}
.mainHeader .mainHeader_wrapper {
  display: grid;
  grid-template-columns: auto auto min-content;
  max-width: 1245px;
  width: 100%;
}
.mainHeader .header_logo {
  width: 251px;
  height: 55px;
  border: 0;
  background: transparent url($logo-img-path) no-repeat left;
  background-size: contain;
}
.header-user-account-container {
  grid-area: span 2 / span 1;
  position: relative;

  .headerMenu_dropdown_elements {
    position: absolute;
    top: 100%;
    right: 0;
    background: #171717;
    padding: 10px;
    z-index: 5;
    display: none;
  }
  .headerMenu_dropdown_elements.expanded {
    display: block;
  }
  .headerMenu_dropdown_elements .headerMenu_link {
    border: 0;
    border-bottom: 1px solid #707070;
    padding: 4px;
    height: auto;
    white-space: nowrap;
    display: block;
  }
}
.mainHeader .header_account {
  display: grid;
  grid-template-columns: 30px max-content 45%;
  width: 100%;
  height: 100%;
  align-items: center;
}
.mainHeader .header_account a {
  display: flex;
  color: $theme-text-color-1;
  font-weight: 700;
}
.mainHeader .header_account .account_username {
  grid-area: span 2 / span 1;
  align-items: center;
  justify-content: right;
  font-size: 18px;
  padding-right: 9px;
}

.mainHeader .header_account .account_icon {
  grid-area: span 2 / span 1;
  align-items: center;
  justify-content: right;
  font-size: 18px;
  padding-right: 9px;

  .user-icon-header {
    width: 30px;
    height: 21px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: $icon-header-user-path;
  }
}

.mainHeader .header_account .account_username:after {
  content: '';
  display: block;
  position: relative;
  width: 1px;
  height: 34px;
  right: -9px;
  background-color: $color__mainHeader;
}
.mainHeader .header_account .account_balance,
.mainHeader .header_account .account_exposure {
  font-size: 13px;
  padding-left: 8px;
  white-space: nowrap;
}
.mainHeader .header_account .account_balance {
  align-self: end;
}
.mainHeader .header_account .account_exposure {
  align-self: start;
}
.mainHeader .headerMenu {
  font-size: 0;
}
.mainHeader .headerMenu_link {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 700;
  color: $theme-text-color-1;
  height: 28px;
  line-height: 28px;
}
.subHeader {
  width: 100%;
  color: $color__subHeader;
  background-color: $background-color__subHeader;
}
.subHeader .subHeader_wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
}
.subHeader .subheader_right {
  text-align: right;
}
.subHeader .headerMenu {
  font-size: 0;
  display: flex;
  position: relative;
}

.subHeader .headerMenu .headerMenu_link_sports_wrapper {
  display: flex;
  position: relative;
  overflow: hidden;
  overflow-x: auto;

  .headerMenu_link_sports_wrapper-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    pointer-events: none;

    &.headerMenu_link_sports_wrapper-left-arr {
      left: 0;
      z-index: 1;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    &.headerMenu_link_sports_wrapper-right-arr {
      right: 0;
      z-index: 1;
      background: linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
}

.subHeader .headerMenu_link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 12px;
  color: #fff;
  border-left: 1px solid $border-color__subHeader_link;
}

.subHeader .subheader_right .headerMenu_link {
  font-size: 14px;
}

.subHeader .headerMenu_link:last-child {
  border-right: 1px solid $border-color__subHeader_link;
}
.subHeader .headerMenu_link:first-child {
  border-left: 0;
  border-right: 0;
}
.subHeader .headerMenu_link .headerMenu-icon {
  display: block;
  width: 32px;
  height: 32px;
  margin: 2px 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.subHeader .headerMenu_dropdown {
  padding-right: 30px;
}
.subHeader .headerMenu_dropdown_elements {
  position: absolute;
  top: 100%;
  left: 0;
  background: #171717;
  padding: 10px;
  z-index: 5;
  display: none;
}
.subHeader .headerMenu .headerMenu_dropdown_elements.expanded {
  display: block;
}
.subHeader .headerMenu_dropdown_elements .headerMenu_link {
  border: 0;
  border-bottom: 1px solid #707070;
  padding: 6px 4px;
  height: auto;
  white-space: nowrap;
}
.subHeader .headerMenu_dropdown:after {
  content: '';
  display: block;
  position: absolute;
  width: 14px;
  height: 12px;
  top: 8px;
  right: 8px;
  background: transparent $icon-header-arrow-dropdown-path no-repeat right
    center;
}
.subHeaderTop .subHeader_wrapper {
  height: 40px;
}
.subHeaderTop .headerMenu_link {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-family: $font-family-2;
}
.subHeaderBottom .headerMenu_link {
  height: 28px;
  font-weight: 700;
  white-space: nowrap;
}
.regulatoryMessage {
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  font-family: $font-family-2;
}

.subheader_right {
  font-family: $font-family-2;
}

.header-my-markets-btn,
.header-open-bets-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  font-size: 9px;
  font-weight: normal;
  color: #fff;
  border-radius: 6px;
  line-height: normal;
  border: none;
  text-align: center;

  align-items: center;
  justify-content: center;

  width: 53px;
  height: 45px;
  position: absolute;
  right: 72px;
  top: 3px;

  &.active {
    background-color: $theme-bg-color-1;
    color: $theme-text-color-1;
  }

  .header-my-markets-btn-icon {
    display: block;
    width: 32px;
    height: 32px;
    margin: 2px 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .header-my-markets-btn-title,
  .header-open-bets-button-title {
    // display: none;
    font-size: 10px;
    padding: 3px 0;
    white-space: nowrap;
  }
}

// issue #541 -> https://github.com/idnc-soft/project-manhattan/issues/541
.subHeader.subHeaderTop {
  display: none;
}

@media screen and (min-width: $screen-width-tablet-min-width) {
  .mainHeader .headerMenu_link:hover,
  .mainHeader .headerMenu_link.active {
    color: $color__mainHeader_link__active;
    background-color: $background-color__mainHeader_link__active;
  }
  .subHeader .headerMenu_link:hover,
  .subHeader .headerMenu_link.active {
    color: $color__subHeader_link__active;
    background-color: $background-color__subHeader_link__active;
  }
}
@media screen and (max-width: $screen-width-tablet-max-width) {
  .mainHeader .header_account {
    grid-template-columns: auto 50px;

    .account_icon {
      grid-area: span 4 / span 1;
      grid-column: 2;
      background-color: #303030;
      color: #fff;
      align-self: center;
      width: 46px;
      height: 41px;
      padding: 0;
      font-size: 8px;
      text-align: center;
      margin: 6px 0;

      .user-icon-header {
        height: 19px;
        width: 16px;
        margin: 5px auto;
        background-image: $icon-header-user-path-mobile;
      }

      .account_icon-username {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 3px;
      }
    }

    .account_username {
      grid-column: 1;
      grid-row: 1;

      &:after {
        content: none;
      }
    }
  }

  .subHeader {
    // border-top: 2px solid $theme-bg-color-1-active;

    .subHeader_left {
      overflow: hidden;
      overflow-x: auto;
      padding-left: 5px;
    }
  }

  .subHeader.subHeaderTop {
    display: none;
  }

  .subHeader.subHeaderBottom .headerMenu .headerMenu_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 60px;
    height: 46px;
    margin: 5px 2px;
    font-size: 10px;
    font-weight: normal;
    color: #fff;
    line-height: normal;
    border: none;
    background-color: #424242;
    text-align: center;
    min-width: 60px;
    max-width: 60px;
    white-space: normal;
    padding: 5px;
  }
  .subHeader.subHeaderBottom .headerMenu .headerMenu_link.active {
    color: $theme-text-color-1;
    background-color: $theme-bg-color-1;
  }

  .subHeader .headerMenu .headerMenu_dropdown:after {
    display: none;
  }

  .subHeader .headerMenu .headerMenu_dropdown_elements {
    right: 0;
    left: auto;
    width: auto;

    .headerMenu_link {
      width: auto;
      background-color: transparent;
      font-size: 14px;
      height: auto;
      line-height: normal;
      padding: 8px 0;
      border-bottom: 1px solid #707070;
      border-radius: 0;
    }
  }

  .subHeader .subHeader_wrapper {
    grid-template-columns: auto 0;
    background-color: #7d7d7d;
  }
  .regulatoryMessage {
    text-align: left;
    padding: 0 25px;
    font-size: 12px;
  }
  .mainHeader {
    height: auto;

    .mainHeader_wrapper {
      background-color: $theme-bg-color-1;
    }

    .headerMenu {
      background-color: #303030;
      display: block;
      width: 100%;
      grid-column-start: 1;
      grid-column-end: 4;
      position: relative;
    }

    .headerMenu_link {
      font-size: 12px;
      padding: 5px;
      margin: 6px;
      border-radius: 5px;
      height: 40px;
      color: #fff;
      &.active {
        background-color: $theme-bg-color-1;
        color: $theme-text-color-1;
      }
    }

    .header-betslip-button,
    .header-open-bets-button {
      width: 53px;
      height: 45px;
      position: absolute;
      right: 10px;
      top: 3px;
      border-radius: 6px;

      &.active {
        background-color: $theme-bg-color-1;
      }

      .header-betslip-button-icon {
        display: block;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .header-my-markets-btn,
    .header-open-bets-button {
      border-radius: 0;
      background-color: #7d7d7d;
      margin-left: 5px;
      width: 57px;

      &.active {
        background-color: $theme-bg-color-1;
        color: $theme-text-color-1;
      }
    }

    .header_logo {
      margin-top: 20px;
      margin-left: 10px;
      width: 150px;
      height: 25px;
      background-size: contain;
      background-position: left top;
    }

    .header_account {
      .account_username {
        font-size: 14px;
        padding-right: 10px;
        align-items: center;
      }

      .account_username img {
        width: auto;
        height: 30px;
      }

      .account_balance,
      .account_exposure {
        font-size: 11px;
        padding-left: 0;
        padding-right: 10px;
        justify-content: flex-end;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: $screen-width-tablet-max-width) {
  .header.no-top-menu {
    .mainHeader {
      height: 60px;

      .header_logo {
        margin-left: 10px;
      }

      .headerMenu {
        display: none;
      }
    }
  }
}

@media screen and (min-width: $screen-width-tablet-min-width) and (max-width: $screen-width-full-max-width) {
  .mainHeader .mainHeader_wrapper {
    grid-template-columns: auto auto min-content;
  }

  .subHeader .subHeader_wrapper {
    grid-template-columns: auto auto 300px;
  }

  .subHeader .subheader_right {
    display: grid;
    grid-template-columns: auto 40%;

    .headerMenu {
      justify-self: right;
    }
  }

}
@media screen and (min-width: 1245px) {
  .mainHeader {
    .mainHeader_wrapper {
      grid-template-columns: 300px auto min-content;
    }
  }

  .subHeader {
    justify-content: center;
    display: flex;
  }
  .subHeader .subHeader_wrapper {
    grid-template-columns: 2fr 1fr;
    max-width: 1245px;
    width: 100%;
  }
  .subHeader .subHeader_left {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .subHeader .subheader_right {
    grid-column: 4;
    display: grid;
    grid-template-columns: auto 40%;
  }

  .subHeader .headerMenu {
    justify-self: left;
  }
}

@media screen and (max-width: $screen-width-mobile-max-width) {
  /*.header_wrapper{
    overflow-y: hidden;
  }*/
  .subHeader .subHeader_wrapper.subHeader_left {
    overflow: hidden;
    overflow-x: auto;
  }
  .mainHeader {
    .header_logo {
      width: 45%;
    }
    .mainHeader_wrapper {
      grid-template-columns: auto min-content min-content;
      position: relative;
    }
  }

  .subHeader .subHeader_wrapper {
    min-height: 50px;
  }
}

.header.multilingual {
  .mainHeader_wrapper {
    grid-template-columns: min-content auto min-content min-content;
  }
}

@media screen and (max-width: $screen-width-mobile-max-width) {
  .header.multilingual {
    .mainHeader_wrapper {
      grid-template-columns: auto min-content min-content min-content;
    }
  }
}

@media screen and (max-width: $screen-width-tablet-max-width) {
  .header.multilingual {
    .mainHeader .headerMenu {
      grid-column-end: 5;
    }
  }
}

/*
 *
 *
 * LANGUAGES DROPDOWN
 *
 *
 */
.header .languages {
  grid-area: span 2 / span 1;
  position: relative;
  align-self: center;

  .lalnguage-selected_wrapper {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 4px;
    cursor: pointer;
  }

  .languages-list_wrapper {
    position: absolute;
    right: 4px;
    padding: 4px;
    background-color: #171717;
    color: #fff;
    display: none;
    top: 25px;
    z-index: 5;
    font-size: 0.7rem;

    &.active {
      display: block;
    }

    .languages-list-item {
      padding: 3px 5px;
      border-bottom: 1px solid #424242;
      cursor: pointer;
      display: flex;
      gap: 5px;
      align-items: center;

      .laguages-list-item-icon {
        width: 20px;
      }

      .languages-list-item-name {
        white-space: nowrap;
      }
    }
  }
}

@media screen and (min-width: $screen-width-tablet-min-width) {
  .header .languages {
    padding:7px 0 7px 5px;
    margin-left: 5px;
    border-left: 1px solid $color__mainHeader;
  }
}

/**
 *
 *
 * QUICK LOGIN
 *
 *
 **/

.quick-login-wrapper {
  grid-area: span 2 / span 1;

  .quick-login-form {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 10px;
    padding: 0 10px;
  
    .quick-login-fields {
      display: flex;
      flex-direction: column;
      gap: 3px;
  
      input {
        font-size: 0.7rem;
        border-radius: 3px;
        padding: 0 4px;
        border: 1px solid #707070;
        background-color: #FFF;
        color: #000;

        &:focus {
          border-color: $theme-bg-color-11;
        }
      }
    }

    .quick-login-btn {
      padding: 6px 10px;
      cursor: pointer;
      border-radius: 3px;
      color: $color__quick-login-btn;
      font-size: 0.8rem;
      font-weight: 700;
      background-color: $background-color__quick-login-btn;
      white-space: nowrap;
      position: relative;
    }
  }

  .btn-process {
    display: none;
  }

  &.processing {
    .quick-login-mobile-btn {
      pointer-events: none;
    }

    .btn-value {
      opacity: 0;
    }
    .btn-process {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -8px;
      margin-top: -8px;

      .loading-spinner {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.quick-login-mobile-wrapper {
  grid-area: span 2 / span 1;
  position: relative;

  .quick-login-mobile-form-toggle {
    display: flex;
    height: 100%;
    align-items: center;

    /* 
    &.active .ql-toggle-btn {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    */
  }

  .ql-toggle-btn {
    padding: 6px 10px;
    cursor: pointer;
    border-radius: 5px;
    color: $color__quick-login-toggle;
    font-size: 0.8rem;
    font-weight: 700;
    background-color: $background-color__quick-login-toggle;
    white-space: nowrap;
  }
}


.modal-wrapper.notLoggedInMessage {
  .modal-wrapper {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .modal-container {
    min-width: 35%;
    min-height: 150px;
    border: 3px solid $theme-bg-color-12;
    font-weight: bold;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: $screen-width-mobile-max-width) {
  .quick-login-mobile-wrapper {
  
    .ql-toggle-btn {
      text-align: center;
      width: 70px;
      height: 50px;
      align-content: center;
      margin-right: 5px;
    }
  }
}

.quickMobileLoginModal {

  .modal-container {
    border: 2px solid $theme-bg-color-1;
  }

  .quick-login-mobile-form-container {
    padding: 10px;
    right: 0px;
    z-index: 5;
    top: 50%;
    border-radius: 5px;
    border-top-right-radius: 0;
    min-width: 200px;
    width: 50%;
  }

  .quick-login-mobile-form-container {
    flex-direction: column;
    gap: 15px;
    display: flex;

    input {
      font-size: 0.9rem;
      border-radius: 3px;
      padding: 0 4px;
      border: 1px solid #707070;
      background-color: #FFF;
      color: #000;
      line-height: 36px;
      height: 36px;
      width: 100%;

      &:focus {
        border-color: $theme-bg-color-11;
      }
    }
  }

  .quick-login-mobile-btn {
    cursor: pointer;
    border-radius: 3px;
    color: $color__quick-login-mobile-btn;
    font-size: 0.8rem;
    font-weight: 700;
    background-color: $background-color__quick-login-mobile-btn;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    position: relative;
    line-height: 42px;
    height: 42px;
  }

  .btn-process {
    display: none;
  }

  .processing {
    .quick-login-mobile-btn {
      pointer-events: none;
    }

    .btn-value {
      opacity: 0;
    }
    .btn-process {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -8px;
      margin-top: -8px;

      .loading-spinner {
        width: 16px;
        height: 16px;
      }
    }
  }
}
