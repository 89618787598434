.events-list-mobile-wrapper {
    .events-list-mobile-container {
        position: relative;

        .events-list-mobile-toggle {
            width: 14px;
            display: block;
            position: absolute;
            height: 12px;
            right: 10px;
            top: 10px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: $icon-arrows-down--black-full-path;
        }

        .events-list-mobile {
            .events-list-mobile-item {
                background-color: #FFF;
                padding: 7px 10px;
                border-bottom: 1px solid #ededed;
                display: none;

                &.selected {
                    display: block;
                }

            }   
        }

        &.expanded{
            .events-list-mobile-toggle {
                background-image: $icon-arrows-up--black-full-path;
            }
    
            .events-list-mobile {
                .events-list-mobile-item {
                    display: block;    

                    &.selected{
                        background-color: #ededed;
                    }
                }   
            }
        }
     }
}