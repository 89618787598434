.content-left {
  .sports-list-loader-wrapper {
    padding: 20px 0;
  }
}
.left-block h3 {
  width: 100%;
  box-sizing: border-box;
  height: 24px;
  line-height: 24px;
  font-size: 13px;
  color: $color__left_h3;
  background-color: $background-color__left_h3;
  padding: 0 10px;
  overflow: hidden;
  margin: 0;
}
.sports-list-back-button {
  display: block;
  width: 100%;
  height: 24px;
  line-height: 24px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 10px;
  color: #6f6b73;
  background-color: #cccccc;
  font-size: 10px;
}
.sports-list-back-button:after {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 14px;
  top: 5px;
  right: 10px;
  background: transparent $icon-left-arrow-close-path no-repeat
    center center;
}
.block-sports-list {
  display: flex;
  width: 100%;
}
.block-sports-list .sports-list-wrapper {
  width: 100%;
}
.block-sports-list .sports-list-item {
  display: flex;
  width: 100%;
  padding: 3px 10px;
  line-height: 16px;
  box-sizing: border-box;
  position: relative;
  color: $color__left_sports_list_item;
  border-bottom: $border__left_sports_list_item;
  font-size: 11px;

  &.sports-list-group-item{
     
    &.sports-list-group-market {
      display: none;
      padding-left: 20px;

      &.expanded {
        display: block;
      }
    }

    &.expanded {
      background-color: #f4f4f4;

      .sports-list-group-run-marker{
        background-image: $icon-arrows-up--black-full-path;
      }
    }
  }

  .sports-list-group-run-marker{
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    right: 4px;
    top: 5px;
    background-image: $icon-arrows-down--black-full-path;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
.block-sports-list .sports-list-item:hover {
  background-color: $background-color__sports-list-item__hover;
}
.block-sports-list .sports-list-markets .sports-list-item {
  padding-right: 20px;
}
.block-sports-list .sports-list-markets .sports-list-item:after {
  content: '';
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  right: 5px;
  top: 5px;
  background: transparent $icon-left-market-path no-repeat
    center center;
}
.block-sports-list .view-category .sports-list-item,
.block-sports-list .view-game .sports-list-item {
  color: $color__left_sports_list_item;

  &.selected{
    color: $color__left_sports_list_item__selected;
  }
  &.expanded.sports-list-group-run{
    color: $color__left_sports_list_item__expanded;
  }
}

.block-sports-list .view-category .sports-list-item.sport-title,
.block-sports-list .view-game .sports-list-item.sport-title {
  color: $color__sports-list-item_sport-title;
  background-color: $background-color__sports-list-item_sport-title;
  border-bottom: $border-bottom__sports-list-item_sport-title;
  font-weight: $font-weight__sports-list-item_sport-title;
}

.mobile-winner-outrights-wrapper{
  .mobile-winner-outrights-item{
      background-color: #FFF;
      padding: 10px;
      border-bottom: 1px solid #444;
      font-size: 14px;

      &.mobile-winner-outrights-title{
          background-color: #CCC;
          border-top: 2px solid #000;
      }

      &.mobile-winner-outrights-link a{
          display: block;
      }
  }
}
  
@media screen and (max-width: $screen-width-tablet-max-width) {
  .sidePanel {
    position: fixed;
    width: 320px;
    z-index: 10;
    top: 0;
    bottom: 70px;
    transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    border-right: 1px solid #000;
    overflow-y: auto;
  }
  .content-left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  .content-left.left-panel-state-1 {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}