.sport-icon {
  background-image: $icon-sport-icon-default-path;
}
.active .sport-icon {
  background-image: $icon-sport-icon-default-active-path;
}
.sport-icon-1 {
  background-image: $icon-sport-icon-soccer-path;
}
.active .sport-icon-1 {
  background-image: $icon-sport-icon-soccer-active-path;
}
.sport-icon-2 {
  background-image: $icon-sport-icon-tennis-path;
}
.active .sport-icon-2 {
  background-image: $icon-sport-icon-tennis-active-path;
}
.sport-icon-3 {
  background-image: $icon-sport-icon-basketball-path;
}
.active .sport-icon-3 {
  background-image: $icon-sport-icon-basketball-active-path;
}
.sport-icon-4 {
  background-image: $icon-sport-icon-motorsports-path;
}
.active .sport-icon-4 {
  background-image: $icon-sport-icon-motorsports-active-path;
}
.sport-icon-6 {
  background-image: $icon-sport-icon-horseracing-path;
}
.active .sport-icon-6 {
  background-image: $icon-sport-icon-horseracing-active-path;
}
.sport-icon-8 {
  background-image: $icon-sport-icon-greyhounds-path;
}
.active .sport-icon-8 {
  background-image: $icon-sport-icon-greyhounds-active-path;
}
.sport-icon-7 {
  background-image: $icon-sport-icon-cricket-path;
}
.active .sport-icon-7 {
  background-image: $icon-sport-icon-cricket-active-path;
}
.sport-icon-5 {
  background-image: $icon-sport-icon-baseball-path;
}
.active .sport-icon-5 {
  background-image: $icon-sport-icon-baseball-active-path;
}
.icon-exchange {
  background-image: $icon-menu-exchange-path;
}
.active .icon-exchange {
  background-image: $icon-menu-exchange-active-path;
}
.icon-live {
  background-image: $icon-menu-live-path;
}
.active .icon-live {
  background-image: $icon-menu-live-active-path;
}
.icon-search {
  background-image: $icon-menu-search-path;
}
.active .icon-search {
  background-image: $icon-menu-search-active-path;
}

.icon-home {
  background-image: $icon-stickymenu-home-path;
}
.active .icon-home {
  background-image: $icon-stickymenu-home-active-path;
}

.icon-back {
  background-image: $icon-stickymenu-back-path;
}
.active .icon-back {
  background-image: $icon-stickymenu-back-active-path;
}

.icon-favorites {
  background-image: $icon-stickymenu-favorites-path;
}
.active .icon-favorites {
  background-image: $icon-stickymenu-favorites-active-path;
}

.myaccount-icon {
  background-image: $icon-header-user-mobile-path;
  opacity: 0.5;
}

.header-my-markets-btn-icon {
  background-image: $icon-menu-my-markets-path;
}

.active .header-my-markets-btn-icon {
  background-image: $icon-menu-my-markets-active-path;
}