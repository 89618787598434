.ui-el-bet-stake-values-item--wrapper{
    position: relative;

    .ui-el-bet-stake-values-item{
        padding-right: 13px;
      
        /* Firefox */
        -moz-appearance: textfield;
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
    }

    .ui-el-bet-stake-values-item--arrows-container{
        position: absolute;
        right: 1px;
        width: 15px;
        bottom: 1px;
        top: 1px;

        .ui-el-bet-stake-values-item--arrow{
            height: 50%;
            background-color: #eee;
            font-size: 10px;
            line-height: 10px;
            text-align: center;
            cursor: pointer;
            width: 15px;
            background-position: center;
            background-size: 7px auto;
            background-repeat: no-repeat;

            &:hover{
                background-color: #CCC;
            }

            &.ui-el-bet-stake-values-item--arrow-up{
                background-image: $icon-arrows-up--black-full-path;
            }

            &.ui-el-bet-stake-values-item--arrow-down{
                background-image: $icon-arrows-down--black-full-path;
            }
        }
    }
}
