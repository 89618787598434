@media screen and (max-width: $screen-width-mobile-max-width) {
    .bp3-control-group {
        flex-direction: column;
    }
}
@media screen and (max-width: $screen-width-tablet-max-width) {
    .reports-table-wrapper {
        overflow: auto;
    }

    .report-filters-container .container{
        width: auto;
    }
}
