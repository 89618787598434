body,
html {
  background-color: #4d8ed8;
}
.loginPage .login-block .login-block-wrapper .login-block-content .login-logo {
  height: 80px;
  background-position: center;
  margin-bottom: 28px;
}
.sports-moneyline_time.live {
  background-color: #f6c244 !important;
  color: #0d3249 !important;
}
.loginPage .login-block .login-block-wrapper {
  width: 550px;
}
.sports-moneyline .sports-moneyline_game {
  background-color: #0d3249;
  .sports-moneyline_link {
    color: #fff;
  }
}
.sports-moneyline .sports-moneyline_game:hover {
  background-color: #15202e;
}
.sports-moneyline_oddsColumn_wrapper {
  color: #fff;
}

.market-direct-link-container {
  background-color: #175c86;
  color: #fff;
  border-bottom: 1px solid #ccc;
}
.sports-list-back-button {
  color: #fff;
  background-color: #000;
}
.sports-list-scroll {
  background-color: #0d3249;
}
.betSlipContainer,
.openBetsContainer {
  background-color: #000;
}
.openBetsContainer
  .betSlipContainer-betsWrapper.back
  .betSlipContainer-betsHeader {
  background-color: #0c5f97;
}

.openBetsContainer
  .betSlipContainer-betsWrapper.lay
  .betSlipContainer-betsHeader {
  background-color: #d26375;
}
.betSlipContainer-options-wrapper.back {
  background-color: #15202e;
  color: #fff;
}
.betSlipContainer-options-wrapper.lay {
  background-color: #15202e;
  color: #fff;
}
.betSlipContainer-betsWrapper.back .betSlipContainer-betsHeader {
  background-color: #000;
  color: #fff;
}
.betSlipContainer-betsWrapper.back .betSlipContainer-betLines {
  background-color: #0e72b6;
  color: #fff;
  .betSlipContainer-actionField {
    color: #000;
  }
}
.betSlipContainer-betsWrapper.lay .betSlipContainer-betsHeader {
  background-color: #000;
  color: #fff;
}
.betSlipContainer-betsWrapper.lay .betSlipContainer-betLines {
  background-color: #fc95a6;
  color: #fff;
  .betSlipContainer-actionField {
    color: #000;
  }
}
.betSlipContainer .betSlipContainer-line {
  border: none;
}
.custom-virtual-kbd-wrapper {
  background-color: #000;
  border-bottom: 1px solid #0d3249;
}

.cvkbd-quick-stake-button {
  background-color: #0d3249 !important;
  color: #fff !important;
}
.betSlipContainer-summary {
  padding: 18px;
  background-color: #000;
  color: #fff;
  border-top: 2px solid $background-color__mainContent;
}

.betSlipContainer-summary-line.summary-actionBtns .summary-actionBtns-cancel {
  color: #000;
  background-color: #fff;
}

.betSlipContainer-summary-line.summary-actionBtns
  .summary-actionBtns-placeBet:hover {
  color: #fff;
  background-color: #d59e1e;
}

.betSlipContainer-summary-line.summary-actionBtns
  .summary-actionBtns-placeBet:disabled {
  color: $theme-text-color-1;
  background-color: $theme-bg-color-1;
}

.openBetsContainer .summaryWrapper {
  background-color: #000;
}
.my-markets-market-data-container {
  background-color: #0d3249 !important;
  color: #fff;
}
.event-content.market-wrapper .market-title-container {
  background-color: #15202e;
  .market-name {
    color: #fff;
  }
}
.market-direct-link:hover {
  color: #f6c244;
}
.openBetsContainer .betSlipContainer-betsWrapper.back {
  .betSlipContainer-actionField {
    color: #fff;

    input {
      color: #000;
    }
  }
}
.openBetsContainer .betSlipContainer-betsWrapper.lay {
  .betSlipContainer-actionField {
    color: #fff;

    input {
      color: #000;
    }
  }
}
.market-selection-row {
  background-color: #0d3249;
  color: white;
  border: 1px solid $theme-bg-color-19;
  margin-bottom: 0px;
}
.sports-list-item.sport-title {
  background-color: #0e72b6 !important;
}
.market-selection-row.market-selection-head-row {
  color: #fff;
}
.sports-moneyline_bet-btn.back.secondary-level-bet {
  color: #fff;
  background-color: #0b283a;
  border: 1px solid grey;
}

.sports-moneyline_bet-btn.back.secondary-level-bet:hover {
  background-color: #15202e;
}

.sports-moneyline_bet-btn.lay.secondary-level-bet {
  color: #fff;
  background-color: #0b283a;
  border: 1px solid grey;
}

.sports-moneyline_bet-btn.lay.secondary-level-bet:hover {
  background-color: #15202e;
}
.event-content-other-markets-container
  .market-selection-row
  .market-selection-name {
  background-color: #0d3249;
}
.market-selection-head-row {
  background-color: #0c1721 !important;
  color: #fff !important;
  .market-selection-name {
    background-color: #0c1721 !important;
    color: #fff !important;
  }
}
.market-props-wrapper {
  background-color: #0d3249;
  color: #fff;
}
.sports-list-item.selected {
  background-color: #0c1721;
}
.sports-list-item.expanded {
  background-color: #0c1721;
}
@media screen and (max-width: 1024px) {
  .mainHeader .headerMenu {
    background-color: #0e72b6;
  }
  .header_account .account_icon {
    background-color: #0d3249 !important;
  }
  .mainHeader {
    .headerMenu_link.active {
      background-color: #0d3249 !important;
      color: #fff !important;
    }
  }
  .subHeader .headerMenu {
    background-color: #0d3249;
  }
  .header-my-markets-btn,
  .header-open-bets-button {
    background-color: #0e72b6 !important;
    color: #fff !important;
  }
  .header-my-markets-btn.active {
    background-color: #0d3249 !important;
  }
  .header-open-bets-button.active {
    background-color: #0d3249 !important;
  }
  .cvkbd-vkbd-number-button {
    background-color: #4d8ed8 !important;
  }
  .subHeader .headerMenu_link {
    background-color: #15202e !important;
  }
  .subHeader .headerMenu_link.active {
    background-color: #0b4a9d !important;
  }
  .markets-list-mobile-tabs-container
    .markets-list-mobile-tabs
    .markets-list-mobile-tab.active {
    background-color: #0e72b6 !important;
    a {
      color: #fff !important;
    }
  }
  .quick-betslip-wrapper {
    background-color: #000 !important;
  }
  .subHeader .subHeader_left {
    padding-left: 0px !important;
  }
  .custom-virtual-kbd-wrapper.mobile-view {
    background-color: #000 !important;
  }
  .markets-list-mobile-tabs-wrapper {
    background-color: #0d3249;
  }
  .sports-page-mobile-item.sports-page-mobile-title {
    background-color: #15202e !important;
    color: #fff;
    border-left: none;
    padding-left: none;
  }
  .content-main {
    border-left: none;
  }
  .sports-page-mobile-item {
    background-color: #0d3249 !important;
    color: #fff;
  }
  .content-right.rightPanelToggle {
    background-color: #0e72b6 !important;
  }
}
.search-results-table-container {
  background-color: #4d8ed8 !important;
}
.reports-table-row {
  background-color: #0e72b6 !important;
  color: #fff !important;
}
.search-results-table-container a {
  color: #fff !important;
}
.reports-table-header-row th {
  background-color: #0d3249 !important;
  color: #fff;
}

.report-filters-container {
  background-color: #0d3249 !important;
  color: #fff !important;
}
.event-cashouts-headline {
  background-color: #0d3249 !important;
  color: #fff !important;
}
.cashout-item-info-market {
  background-color: #0d3249 !important;
  color: #fff !important;
}

.profit-loss-total {
  color: #fff !important;
}
.profit-loss-market-link {
  color: #fff !important;
}
.market-profit-loss-info-event-name {
  color: #0d3249;
}
.tabsMenu {
  color: #fff;
  background-color: #485a6a;
  .tabsMenu-tab {
    color: #fff !important;
  }
}
.sports-moneyline_bet-btn {
  color: #000;
  border: 1px solid grey;
}
.headerMenu_link.active {
  color: #fff !important;
}
.cashout-item-info-liability {
  color: #fff !important;
}
.event-cashouts-list {
  background-color: #0e72b6 !important;
}
.cashout-item-wrapper {
  background-color: #0e72b6 !important;
}
.headerMenu_dropdown_elements {
  background-color: #0d3249 !important;
}
.modal-wrapper .modal-container {
  background-color: #0d3249 !important;
  color: #fff;
}
.change-password-popup-title {
  background-color: #0e72b6 !important;
}

.open-bets-bet-updates-wrapper-mobile {
  .ui-el-bet-odds-values-item--wrapper {
    .ui-el-bet-odds-values-item {
      color: #000;
    }
  }
  .ui-el-bet-stake-values-item--wrapper .ui-el-bet-stake-values-item {
    color: #000;
  }
}
.no-data-my-markets-container {
  background-color: #0d3249 !important ;
}

// sport-list overrides

.sports-list-back-button {
  background-color: #000;
  color: #fff;
}

.block-sports-list .view-category .sports-list-item,
.block-sports-list .view-game .sports-list-item {
  background-color: #0d3249;
  color: #fff;
}

.block-sports-list .sports-list-item {
  background-color: #0d3249;
}

.block-sports-list
  .view-category
  .sports-list-item.expanded.sports-list-group-run,
.block-sports-list .view-game .sports-list-item.expanded.sports-list-group-run {
  color: #fff;
}

.block-sports-list .sports-list-item.sports-list-group-item {
  &.expanded {
    background-color: #0d3249;
    color: #fff;
    .sports-list-group-run-marker {
      background-image: url('#{$root-icons-path}/FFF/arrows/arrow-down_full_black.svg');
    }

    &.selected {
      background-color: #0e72b6;
      color: #fff;
      .sports-list-group-run-marker {
        background-image: url('#{$root-icons-path}/FFF/arrows/arrow-down_full_black.svg');
      }
    }
  }
}

.block-sports-list .sports-list-item .sports-list-group-run-marker {
  background-image: url('#{$root-icons-path}/FFF/arrows/arrow-up_full_black.svg');
}

.market-wrapper .low-liquidity-overlay,
.sports-moneyline_game .low-liquidity-overlay,
.sports-moneyline_game .prelive-only-overlay {
  background-color: #4d8ed889;
  color: #fb0;
}

.sports-moneyline .sports-moneyline_game-status,
.event-content.market-wrapper .sports-moneyline_game-status {
  background-color: #4d8ed889;
}

.markets-list-mobile-tabs-wrapper {
  background-color: #0d3249;
}
.markets-list-mobile-tabs-container
  .markets-list-mobile-tabs
  .markets-list-mobile-tab.active {
  background-color: #0e72b6 !important;
}

.markets-list-mobile-tabs-wrapper
  .markets-list-mobile-tabs-container
  .markets-list-mobile-tabs
  .markets-list-mobile-tab.active
  a {
  color: #fff;
}

// HR Top events overrides
.hr-top-events-header-wrapper {
  .days-filter-wrapper {
    .days-filter-item {
      background-color: #0d3249;
      color: #fff;

      &.selected {
        background-color: #0e72b6;
        color: #fff;
      }
    }
  }
}

.tabs-switcher-tabs-container {
  display: flex;

  .tabs-switcher-tab {
    background-color: #0e72b6;
    color: #fff;
    border-color: #4d8ed8;

    &.selected {
      background-color: #0d3249;
      color: #fff;
      border-bottom-color: #0d3249;
    }
  }
}

.hr-top-events-event-wrapper {
  background-color: #0d3249;
  color: #fff;
  border-color: #4d8ed8;

  .hr-top-events-event-container {
    border-bottom: 1px solid #4d8ed8;
  }

  .hr-top-events-markets-wrapper {
    .hr-top-events-market {
      background-color: #0e72b6;
      color: #fff;

      a {
        &:hover {
          background-color: darken(#0e72b6, 10%);
          color: #fff;
        }
      }
    }
  }
}
