.cashout-page-wrapper {
    .cashout-page-title {
        display: flex;
        align-items: center;
        padding: 0 8px 0 10px;
        margin: 0;
        height: 32px;
        color: $color__sports-moneyline_sportMiniTitle;
        font-size: 14.4px;
        font-weight: 700;
        background-color: $background-color__sports-moneyline_sportMiniTitle;
    }

    .cashout-page-items-wrapper {
        padding: 20px 0;
    }
}