// overrides variables set in /src/styles/variables/base.scss
// these are the base variables - theme palette, images/icons base paths, layout vars, etc.

// THEME COLORS
// background colors
$theme-bg-color-1: #F6C244;
$theme-bg-color-1-active: #0d3249;
// text colors
$theme-text-color-1: #000;

$theme-text-color-1-active: #FFF;


// icons colors
