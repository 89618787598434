@charset "utf-8";

@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/*
 Hide Google ReCaptcha badge
*/
.grecaptcha-badge { 
    visibility: hidden;
}