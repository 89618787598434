.favorites-page-wrapper {
    .missing-sports-data-message {
        margin: 20px 0;
    }

    .favorites-page-title {
        display: flex;
        align-items: center;
        padding: 0 8px 0 10px;
        margin: 0;
        height: 32px;
        color: $color__sports-moneyline_sportMiniTitle;
        font-size: 14.4px;
        font-weight: 700;
        background-color: $background-color__sports-moneyline_sportMiniTitle;
    }

    .favorites-sport-name {
        display: flex;
        align-items: center;
        padding: 0 8px 0 10px;
        margin: 0;
        height: 32px;
        color: $color__favorites-sport-name;
        font-size: 13px;
        font-weight: 600;
        background-color: $background-color__favorites-sport-name;
    }

    .favorites-page-items-wrapper {
        background-color: $background-color__favorites-page-items-wrapper;
        color: $color__favorites-page-items-wrapper;
    }

    .favorites-page-event-name {
        display: flex;
        align-items: center;
        padding: 0 8px 0 10px;
        margin: 0;
        height: 32px;
        color: $color__sports-moneyline_sportMiniTitle;
        font-size: 14.4px;
        font-weight: 700;
        background-color: $background-color__sports-moneyline_sportMiniTitle;
    }

    .favorites-page-markets-wrapper {
        padding: 5px;
    }
}