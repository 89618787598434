// overrides variables set in /src/styles/variables/variables.scss
// these are variables, that depends on the base variables. for example:
// $section-football-header-bg: $theme-bg-color-1;
// they should be included in theme.scss after base variables

$logo-img-path: '/themes/starfair24/imgs/logo.png';
$login-logo-img-path: '/themes/starfair24/imgs/logo.png';

//login
$background-image__loginPage: '/themes/starfair24/imgs/bgr-login.jpg';

$background-image_login-block-content: none;
$background-color__login-button: $theme-bg-color-19;
$background-color__contact-form-sumit-btn: $theme-bg-color-19;
$background-color__contact-form-sumit-btn__active: #0d3249;
$color__contact-form-sumit-btn: #fff;
$color__login-button: $theme-text-color-19;
$background-color__login-status-message: $theme-bg-color-12;
$padding__login-status-message: 5px 10px;
$loginLogoDisplay: center;

//header
$background-color__subHeader: #0e72b6;

//
$background-color__subHeader_link__active: #0d3249;
$border-color__subHeader_link: #0e72b6;

//main

$background-color__mainContent: #4d8ed8;
$border-color__mainContent: #4d8ed8;
$backround-color__sports_moneyline_Time: #175c86;
$color__sports_moneyline_Time: #fff;
$background-color__sports-moneyline_Title: #0c1721;
$color__sports-moneyline_Title: #fff;
$color__top-events-paginator-wrapper: #fff;
$color__left_sports_list_item__selected: #fff;

//left
$background-color__leftContent: #4d8ed8;
$color__left_sports_list_item: $theme-text-color-5;
$background-color__sports-list-item__hover: #15202e;
$background-color__left_h3: #0c1721;
$background-color__sports-list-item_sport-title: #0d3249;

//topevents:
$background-color__bet-button-back: #a6d8ff;
$background-color__bet-button-back__active: #6cbdff;

$background-color__bet-button-lay: #fac9d1;
$background-color__bet-button-lay__active: #f694aa;
$background-color__favorites-sport-name: #15202e;
$color__favorites-sport-name: #fff;
$background-color__favorites-page-items-wrapper: #15202e;
//betslips:
$color__betslip_empty_message: #fff;
$background-color__betslip_tab_active: #000;
$background-color__betslip_tab_active_accent: #000;

//icons
.active .icon-exchange {
  background-image: $icon-menu-exchange-path !important;
}
.active .icon-live {
  background-image: $icon-menu-live-path !important;
}

.active .icon-search {
  background-image: $icon-menu-search-path !important;
}

.active .icon-home {
  background-image: $icon-stickymenu-home-path !important;
}

.active .icon-back {
  background-image: $icon-stickymenu-back-path !important;
}
.active .icon-favorites {
  background-image: $icon-stickymenu-favorites-path !important;
}
.active .header-my-markets-btn-icon {
  background-image: $icon-menu-my-markets-path !important;
}
.active .sticky-icon-mybet {
  background-image: $icon-stickymenu-mybet-path !important;
}

$background-color__bet-button-secondary-level__active: #3c97ff;
