.live-tracker-wrapper {
    background-color: #000;
    color: #FFF;
}

.right-panel-live-tracker-wrapper {
    height: 270px;
}

.live-tracker-container {
    height: 270px;
}

.live-tracker-mobile-container {
    width: 100%;
    height: 270px;

    &.live-tracker-mobile-lt {
        width: 600px;
        height: 350px;
        margin: 0 auto;
    }
}

.live-tracker-mobile-type-switch-wrapper {
    display: flex;
    align-content: center;
    margin-bottom: 10px;

    .live-tracker-mobile-switch-item {
        height: 40px;
        background-color: $theme-bg-color-4;
        color: $theme-text-color-4;
        flex-grow: 1;
        align-content: center;
        cursor: pointer;
        text-align: center;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 100%;
        
        &.active {
            background-color: $theme-bg-color-1;
            color: $theme-text-color-1;
        }

        &.live-tracker-mobile-switch--lt {
            background-image: $icon-media-livetracker-path;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

.live-tracker-in-top-events-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: nowrap;
    width: 100%;
    align-self: stretch;
}

.live-tracker-intop-event-football-period,
.live-tracker-intop-event-tennis-sets {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.2);
    flex-direction: column;
}

.live-tracker-intop-event-football-score,
.live-tracker-intop-event-tennis-games {
    flex-grow: 1;
    flex-basis: 0;
}

.live-tracker-intop-event-basketball-period {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.2);
    flex-direction: column;
}

.live-tracker-intop-event-basketball-score {
    flex-grow: 1;
    flex-basis: 0;
}

@media screen and (min-width: $screen-width-tablet-min-width) {
    .live-tracker-wrapper.center-panel-live-tracker-wrapper {
        margin: 0 18px 1px 18px;
    }
}


@media screen and (min-width: 1724px) {
    .right-panel-live-tracker-wrapper {
        height: 350px;
    }
}

@media screen and (max-width: 660px) {
    .live-tracker-mobile-container {
    
        &.live-tracker-mobile-lt {
            width: 100%;
        }
    }
}

@media screen and (max-width: 470px) {
    .live-tracker-mobile-container {
    
        &.live-tracker-mobile-lt {
            width: 100%;
            height: 260px;
        }
    }
}