.top-events-paginator-wrapper{
    padding: 4px 10px;
    color: $color__top-events-paginator-wrapper;

    .top-events-paginator-item-container{
        display: inline-block;
        padding: 0 10px 0 0;
        margin-right: 10px;
        border-right: 1px solid $border-color__top-events-paginator-item-container;

        .top-events-paginator-item-value{
            font-weight: bold;
        }
    }

    .top-events-paginator-page{
        display: inline-block;
        background-color: $background-color__top-events-paginator-page;
        border-radius: 3px;
        padding: 4px 10px;
        border: 1px solid $border-color__top-events-paginator-page;
        color: $color__top-events-paginator-page;
        margin: 0 2px;

        &:hover {
            color: $color__top-events-paginator-page_hover;
            background-color: $background-color__top-events-paginator-page_hover;
            text-decoration: none;
        }

        &.active {
            background-color: $background-color__top-events-paginator-page_active;
            color: $color__top-events-paginator-page_active;
        }
    }
}